.MuiFormControl-root{
    width: 100%;
    .MuiInputLabel-root{
        color: #313131 !important;
        transform: none;
        font-size: $font16;
        line-height: $lheight20;
        font-family: "NunitoSans-SemiBold",sans-serif;
        position: relative;
    }
    .MuiInputBase-root{
        font-size: 14px;
        line-height: 18px;
        color: #313131;
        border-radius: 6px;
        border: 1px solid #aeb5c5;
        height: 40px;
        margin-top: 0px;        
        padding: 0px 20px;
        width: 100%;
        &::before{
            content: none;
        }
    }
    .MuiInputAdornment-root{
        .MuiIconButton-root{
            &:focus{
                outline: none;
            }
        }
    }
}
.Mui-focused{
    color: $black-color !important;
}
.MuiInput-underline{
    &::after{
        content: none !important;
    }
}
.MuiPickersPopper-root{
    .MuiPickersDay-root.Mui-selected{
        background-color: $light-green-color !important;
        outline: none;
    }
    button:focus{
        outline: none;
    }
}
.MuiPickersMonth-root.Mui-selected{
    color: $light-green-color !important;
}