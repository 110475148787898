.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: $light-green-color;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: none;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $light-green-color;
  }
}
