.status-popup{
    .MuiDialog-container{
        .MuiPaper-root{
            border-radius: 12px;       
            width: 500px;
            box-shadow: 0px 16px 40px rgba(49,49,49,0.19);
            .cancel-btn{
                cursor: pointer;
            }
            .MuiDialogContent-root {
                border: none;  
                padding: 0px 30px 30px 30px; 
                @include sm{
                    padding: 0px 20px 20px 20px;
                }   
                .select-block{
                    margin-top: 25px;
                    background-color: $lightblue-back-color;
                    border-radius: 5px;
                    cursor: pointer;
                    @include transition;
                    padding: 15px;
                    .select-block-title{
                        @include sm{
                            margin-top: 15px;
                        }
                        .select-block-title-text{
                            color: $black-color;
                        }
                    }
                    &:hover,&.active{
                        background-color: $white-color;
                        box-shadow: 0px 16px 40px rgba(154,170,207,0.20);
                        .select-block-title{
                            .select-block-title-text{
                                color: $light-green-color;
                            }
                        }
                    }
                }                            
                
            }
        }
    }
}
.application-confirmation-popup{
    .MuiDialog-container{
        .MuiPaper-root{     
            .MuiDialogContent-root {
                padding: 30px;
                @include sm{
                    padding: 20px;
                }
                .title-panel{
                    .title-panel-text{
                        font-size: $font16;
                        line-height: $lheight22;
                        @include md{
                            font-size: $font16;
                            line-height: $lheight20;
                        }
                    }
                }
            }
        }
    }
}