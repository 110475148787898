.resources-banner-section,
.banner-mobile-section.resources-mobile-section {
    background: url("../../images/resources/resources-banner.png") no-repeat;
    background-size: 75%;
    background-position: top right !important;
    @include xl {
        background-size: cover;
    }
    @include md {
        background-position: bottom left !important;
    }
    .banner-description {
        padding-left: 100px;
        @include sm {
            padding-left: 32px;
        }
    }
}
.resources-section {
    padding: 150px 0 50px;
    @include lg{
        padding: 50px 0;
    }
    .resource-data-panel {
        max-width: 70%;
        margin: 0 auto 150px;
        text-align: center;
        @include xl{
            max-width: 80%;
            margin: 0 auto 80px;
        }
        @include sm{
            max-width: 100%;
        }
        .resource-detail {
            font-size: $font20;
            line-height: $lheight28;
            @include sm{
                font-size: $font16;
            }
        }
    }
    .resource-title {
        font-size: $font30;
        line-height: $lheight34;
        @include xl{
            font-size: $font25;
        }
    }
    .resource-block {
        @include lg{
            margin-bottom: 30px;
        }
        .default-data {
            height: 320px;
            background-position: center !important;            
            overflow: hidden;
            flex-direction: column;
            position: relative;
            .resource-details-panel {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                margin: auto;
                z-index: 9;
                flex-direction: column;
                padding: 0 60px;
                @include laptop{
                    padding: 0 30px;
                }
                @include xl{
                    padding: 0 20px;
                }
            }
            // &.business-block {
            //     background: url("../../images/resources/business-resource.png") no-repeat;
            // }
            // &.individuals-block {
            //     background: url("../../images/resources/individuals-resource.png") no-repeat;
            // }
            // &.gov-agency-block {
            //     background: url("../../images/resources/gov-agency-resource.png") no-repeat;
            // }
            .overlay {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                &.yellow-overlay {
                    background: $resource-yellow-overlay;
                }
                &.blue-overlay {
                    background: $resource-blue-overlay;
                }
            }
        }
        &:hover {
            .resources-inner-details {
                //display: block;
                opacity: 1;
            }
        }
        .resources-inner-details {
            background: $white-color;
            box-shadow: 0 8px 50px rgba(0, 0, 0, 0.15);
            border-radius: 10px;
            padding: 20px;
            @include transition;
            opacity: 1;
            //display: none;
            height: 570px;
            overflow-y: auto;
            @include xl{
                padding: 10px;
            }
            @include lg{
                height: auto;
            }
            .dates-block {
                margin-bottom: 40px;
                .dates-block-title {
                    color: blue;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }
    }
}

.flip-card {
    width: 100%;
    height: 100%;
    perspective: 1000px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 1s;
    transform-style: preserve-3d;
    img{
        height: 100%;
        object-fit: cover;
    }
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.flip-card-back {
    transform: rotateY(180deg);
}
