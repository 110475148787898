.form-preview-page {
    padding: 50px 0px;
    .btn-pdf{
        @include sm{
            width: 120px;
            height: 35px;
            margin-left: 0px !important;
        }
    }
    .heading-panel {
        @include sm {
            margin-bottom: 20px;
        }
    }
    .heading-right-section {
        display: block;
        justify-content: flex-end;
        @include md {
            flex-wrap: wrap;
            justify-content: flex-start;
        }
        .application-block {
            width: 255px;
            margin-right: 40px;
            @include md {
                margin: 10px 30px 10px 0px;
            }
            .color-label {
                width: 135px;
            }
        }
        .payment-block {
            width: 255px;
            @include md {
                margin: 10px 0px 10px 0px;
            }
        }
        .btn-medium{
            height: 40px;
        }
    }
    .color-label {
        width: 135px;
        text-align: center;
        padding: 5px 0px;
        margin: 0 auto;
        margin-right: 0;        
    }
    .preview-block {
        margin-top: 30px;
        padding-bottom: 50px;
        border-bottom: 1px solid $gray-border-color;
        &:last-child {
            border-bottom: none;
        }
        @include md {
            padding-bottom: 20px;
        }
        .block-heading {
            .block-heading-text {
                position: relative;
                padding-left: 40px;
                &::before {
                    content: "";
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 22px;
                    height: 27px;
                }
                &.about::before {
                    background: url("../../../images/svg-icon/stepper-about-inprogress.svg") no-repeat;
                }
                &.family::before {
                    background: url("../../../images/svg-icon/stepper-family-inprogress.svg") no-repeat;
                }
                &.residence::before {
                    background: url("../../../images/svg-icon/stepper-residence-inprogress.svg") no-repeat;
                }
                &.cra::before {
                    background: url("../../../images/svg-icon/stepper-accounting-inprogress.svg") no-repeat;
                }
                &.upload::before {
                    background: url("../../../images/svg-icon/stepper-upload-inprogress.svg") no-repeat;
                }
                &.notes:before{ 
                    background: url("../../../images/svg-icon/preview-notes.svg") no-repeat;
                }
                &.deposite{
                    background: url("../../../images/svg-icon/deposit-green-icon.svg") no-repeat;
                }
            }
        }
        .data-block {
            margin-top: 20px;
            .data-row {
                margin-bottom: 20px;
                .right-panel {
                    margin-left: 30px;
                    @include md {
                        margin-left: 0px;
                        margin-top: 5px;
                    }
                }
            }
            &.notes-block{
                border: 1px solid $gray-border-color;
                padding: 20px;
            }
        }
    }
    .pdf-btn{
        .mt-30{
            @include sm{
                margin-top: 10px !important;
            }
        }
    }
    .application-btn{
        @include sm{
            margin-top: 10px !important;
        }
        .btn-medium{
            @include sm{
                height: 35px;
                margin-right: 10px;
            }
        }
    }
    .button-panel {
        // @include md{
        //     flex-direction: column;
        // }
        .mt-30{
            @include sm{
                margin-top: 10px !important;
            }
        }
        .pay-btn {
            margin-left: 30px;
            // @include lg{
            //     margin-left: 10px;
            // }
            // @include md{
            //     margin-left: 0px;
            // }
            @include sm{
                margin-left: 10px;
                margin-right: 10px;
            }
        }
        .pdf-btn{
            .btn-medium{
                height: 40px;
            }
        }
    }
}
