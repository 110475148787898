.address-suggestion-list {
    border: 1px solid $light-gray-color;
    border-radius: 6px;
    margin-top: 5px;
    max-height: 170px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    // scrollbar-width: thin;
    // scrollbar-color: $light-green-color $light-gray-color;
    &::-webkit-scrollbar {
        width: 5px;
    }
    &::-webkit-scrollbar-track {
        box-shadow: none;
        border-radius: 0 10px 10px 0;
        background: $white-color;
        border-bottom: 1px solid $light-gray-color;
        border-top: 1px solid $light-gray-color;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $light-green-color;
    }
    .suggestion-item {
        cursor: pointer;
        padding: 10px 20px;
        color: $black-color;
        white-space: normal;
        &:last-child {
            margin-bottom: 0px;
        }
        &:hover {
            background-color: $gray-border-color;
        }
        &:active {
            color: $black-color;
        }
        .dropdown-item-panel-text {
            font-size: $font14;
            line-height: $lheight18;
            color: $black-color;
        }
    }
}
