.file-input {
    position: absolute;
    opacity: 0;
    left: 29px;
    max-width: 200px;
    bottom: 20px;
    height: 30px;
}
.upload-accordion{
    margin: 50px 0px;    
    .dependent-title{
        margin-bottom: 15px;
        .dependent-title-text{
            font-size: $font16;
            line-height: $lheight20;
            @include md{
                font-size: $font14;
                line-height: $lheight18;
            }
        }
    }
    .accordion{
        .card{
            margin-bottom: 20px;
            border: 1px solid $light-gray-color;
            .card-header{
                padding: 0px;
                border: none;
                background: $table-background-color;
                button{
                    padding: 20px;
                    width: 100%;
                    border: none;
                    background: transparent;
                    text-align: left;
                    color: $light-gray-color;
                    &:focus{
                        border: none;
                        outline: none;
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        top: 25px;
                        right: 20px;
                        border: solid $dark-back-color;
                        border-width: 0 1px 1px 0;
                        display: inline-block;
                        padding: 3px;
                        transform: rotate(45deg);
                        -webkit-transform: rotate(45deg);
                        transition: 300ms ease-in-out;
                    }
                }
                &.active{               
                    button{
                        color: $dark-back-color;
                        &::after{                        
                            transform: rotate(-135deg);
                            -webkit-transform: rotate(-135deg);
                        }
                    }                   
                }   
            }                  
            .card-body{
                max-height: 620px;
                overflow: auto;
            }
        }
    }
}
.upload-block {
    .upload-container {        
        border: 1px solid $light-gray-color;
        border-radius: 6px;
        max-width: 190px;
        padding: 10px;
        position: relative;
        .drop-icon {
            margin-top: 20px;
        }
        .drop-text {
            max-width: 173px;
            margin: 20px auto;
        }
    }
}
.document-checklist-block {
    background: $document-checklist-bg-color;
    padding: 20px;
    border-radius: 6px;
    .document-checklist-heading {
        text-align: center;
        .document-checklist-heading-text {
            font-size: $font18;
            line-height: $lheight22;
            font-family: "NunitoSans-Bold", sans-serif;
        }
    }
    .document-checklist-subheading {
        margin-top: 10px;
        text-align: center;
        .document-checklist-subheading-text {
            font-size: $font14;
            line-height: $lheight18;
            font-family: "NunitoSans-Bold", sans-serif;
        }
    }
    .document-point-block {
        margin-top: 25px;
        .document-point-panel {
            margin-bottom: 15px;
            @include lg {
                margin-bottom: 0px;
            }
            .point-panel {
                .point-panel-text {
                    font-size: $font12;
                    line-height: $lheight16;
                    font-family: "NunitoSans-Bold", sans-serif;
                    color: $light-gray-color;
                }
            }
            .second-point{
                margin-top: 15px;
                @include lg{
                    margin-top: 0px;
                }
            }
        }
    }
    .green-tick-icon {
        position: relative;
        padding-left: 30px;
        &::before {
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            background: url("../../images/svg-icon/green-tick-icon.svg");
            background-size: 100%;
            left: 0;
            top: 3px;
        }
    }
    .blue-tick-icon {
        position: relative;
        padding-left: 30px;
        &::before {
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            background: url("../../images/svg-icon/blue-tick-icon.svg");
            background-size: 100%;
            left: 0;
            top: 3px;
        }
    }
}
.document-display-container {
    margin-top: 20px;
    flex-wrap: wrap;
    text-align: center;    
    .document-single-view{
        margin: 0 5px 10px;
    }
     .document-box{
        max-width: 200px;
        max-width: 130px; 
        width: 100%;
        margin:0px 20px 20px 0px;     
     }
     .document-type-name {
         margin-bottom: 10px;
        position: relative;
        .document-type-name-text {
             font-size: $font14;
             line-height: $lheight18;
             font-family: "NunitoSans-SemiBold", sans-serif;
         }
        .name-tooltip{
            display: none;
            border-radius: 6px;
            max-width: 200px;
            top: 22px;
            box-shadow: 1px 1px 6px rgba(0,0,0,0.16);
            position: absolute;
            background-color: $gray-shade-color;           
            z-index: 1;
            border: 1px solid $light-gray-color;
            padding: 5px;
            transition: 300ms ease-in-out;
            left: 0;
            right: 0;
            margin: 0 auto;
            .name-tooltip-text{
                font-size: $font12;
                line-height: $lheight16;
                display: block;
                font-family: "NunitoSans-SemiBold", sans-serif;
            }
        }       
        &:hover{
            .name-tooltip{
                display: block;
            }           
        }        
     }
    .image-overlay {
        position: absolute;
        border-radius: 5px;
        background: $overlay-color;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }
    .document-block {
        padding: 35px;       
    }
    .cancel-icon {
        cursor: pointer;
        position: absolute;
        background: url("../../images/svg-icon/cancel-icon.svg");
        width: 25px;
        height: 25px;
        top: 5px;
        right: 5px;
        background-position: center;
    }
    .download-icon {
        cursor: pointer;
        position: absolute;
        background: url("../../images/svg-icon/download-icon.svg");
        width: 25px;
        height: 25px;
        border: 0;
        top: 5px;
        right: 5px;
        background-position: center;
        outline: none;
    }
}

.MuiPaper-root {
    .MuiDialogTitle-root {
        position: relative;
        .upload-popup-heading {
            .upload-popup-heading-text {
                font-size: $font18;
                line-height: $lheight22;
                font-weight: 400px;
                @include md {
                    font-size: $font16;
                    line-height: $lheight20;
                }
            }
        }
        .cancel-btn {
            position: absolute;
            right: 10px;
            top: 10px;
        }
    }
    .MuiDialogContent-root {
        //margin: 30px 0px;
        .upload-popup {
            padding: 30px 0;
            .upload-block {
                .upload-container {
                    margin: 30px auto;
                    max-width: 161px;
                    padding: 20px 0px;
                    .select-from-computer {
                        margin-top: 20px;
                        .btn-medium {
                            font-size: $font12;
                            line-height: $lheight16;
                            height: 30px;
                            padding: 0px;
                            width: 138px;
                        }
                    }
                    .file-input {
                        left: 11px;
                        max-width: 138px;
                    }
                }
            }
        }
    }
}
