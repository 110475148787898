.loadWrapper{
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 0;
    right: 0;
    z-index: 9999;
    background: rgba(0,0,0,0.7);
    .loader{
        width: 120px;
        height: 70px;
        .rotate-center{
            width: 100%;
        }
    }
}