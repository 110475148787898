.btn{
    font-size: $font18;
    cursor: pointer;
    text-align: center;
    border: 1px solid $light-green-color !important;
    @include transition;
    @include box-shadow;
    border-radius: 8px;
    @include md{
        font-size: $font14;
        line-height: $lheight20;
    }
    // &:active{
    //     background-color: $light-green-color !important;
    // }
    &.disabled{
        opacity: 1 !important;
        background-color: $button-disabled-color !important;
        border-color: $button-disabled-color !important;
    }

    &.btn-primary{
        background-color: $light-green-color;
        color: $white-color;
    }
    
    &.btn-large{
        width: 240px;
        height: 50px;
        @include md{            
            width: 200px;
            height: 40px;
        }        
    }
    &.btn-medium{
        font-size: $font16;
        line-height: $lheight20;
        width: 160px;
        height: 40px;
        @include md{            
            width: 150px;
            font-size: $font14;
        } 
        // @include sm{
        //     width: 120px;
        // }
    }
    &.btn-small{      
        width: 125px;
        height: 40px;  
        font-size: $font14;     
        line-height: $lheight24;
        @include sm{
            width: 95px;
            height: 35px;
            line-height: $lheight22;
        }
        &.btn-acknowledge{
            @include sm{
                width: 120px;
            }
        }
    }
    
    &:hover{
        border: 1px solid $light-green-color;
        background-color: $white-color;
        color: $light-green-color;
    }
    &:focus{
        border: 1px solid $light-green-color;
        background-color: $white-color;
        color: $light-green-color;
        box-shadow: none;
    }
    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
        &:hover{
            border: 1px solid $light-green-color;
            background-color: $light-green-color;
            color: $white-color;
        }
    }
}


