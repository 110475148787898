.tax-form {
    margin-bottom: 40px;

    //stepper block
    .stepper-block {
        margin-top: 80px;
        @include xl {
            margin-top: 50px;
        }
        @include md {
            margin-top: 30px;
        }
        .stepper-heading {
            @include md {
                margin-right: 0px;
            }
            @include lg {
                flex-wrap: wrap;
            }
            .heading-panel {
                margin-bottom: 15px;
                margin-right: 30px;
            }
            .bullet-point {
                margin-bottom: 15px;
            }
        }
        .stepper-container {
            margin-top: 25px;
            .step-block {
                position: relative;
                border: 1px solid transparent;
                @include box-shadow;
                width: 100%;
                @include transition;
                color: $black-color;
                padding: 20px 0px 20px 30px;
                text-align: center;
                border-radius: 6px;
                margin: 10px;
                @include xl {
                    padding: 50px 10px 10px 10px;
                    height: 100px;
                }
                @include md {
                    margin: 5px;
                    padding: 20px 0px;
                    height: 0px;
                }
                .step-block-text {
                    font-size: $font14;
                    line-height: $lheight18;
                    @include lg {
                        font-size: $font12;
                    }
                    @include md {
                        display: none;
                    }
                }
                &:first-child {
                    margin-left: 0px;
                }
                &:last-child {
                    margin-right: 0px;
                }
                &:before {
                    content: "";
                    position: absolute;
                    width: 25px;
                    height: 30px;
                    left: 20px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    background-position: center !important;
                    @include laptop {
                        left: 10px;
                    }
                    @include xl {
                        left: 0;
                        right: 0;
                        top: 20px;
                        margin: 0 auto;
                    }
                    @include md {
                        top: 0;
                        margin: auto;
                    }
                }
                &.about::before {
                    background: url("../../../images/svg-icon/stepper-about.svg") no-repeat;
                }
                &.family::before {
                    background: url("../../../images/svg-icon/stepper-family.svg") no-repeat;
                }
                &.residence::before {
                    background: url("../../../images/svg-icon/stepper-residence.svg") no-repeat;
                }
                &.account::before {
                    background: url("../../../images/svg-icon/stepper-accounting.svg") no-repeat;
                }
                &.upload::before {
                    background: url("../../../images/svg-icon/stepper-upload.svg") no-repeat;
                }

                //in-progress

                &.in-progress {
                    border: 1px solid $light-green-color;
                    &.about::before {
                        background: url("../../../images/svg-icon/stepper-about-inprogress.svg") no-repeat;
                    }
                    &.family::before {
                        background: url("../../../images/svg-icon/stepper-family-inprogress.svg") no-repeat;
                    }
                    &.residence::before {
                        background: url("../../../images/svg-icon/stepper-residence-inprogress.svg") no-repeat;
                    }
                    &.account::before {
                        background: url("../../../images/svg-icon/stepper-accounting-inprogress.svg") no-repeat;
                    }
                    &.upload::before {
                        background: url("../../../images/svg-icon/stepper-upload-inprogress.svg") no-repeat;
                    }
                }

                //completed

                &.completed {
                    border: 1px solid $light-green-color;
                    background: $light-green-color;
                    color: $white-color;
                    &.about::before {
                        background: url("../../../images/svg-icon/stepper-about-completed.svg") no-repeat;
                    }
                    &.family::before {
                        background: url("../../../images/svg-icon/stepper-family-completed.svg") no-repeat;
                    }
                    &.residence::before {
                        background: url("../../../images/svg-icon/stepper-residence-completed.svg") no-repeat;
                    }
                    &.account::before {
                        background: url("../../../images/svg-icon/stepper-accounting-completed.svg") no-repeat;
                    }
                    &.upload::before {
                        background: url("../../../images/svg-icon/stepper-upload-completed.svg") no-repeat;
                    }
                }
            }
        }
    }

    //form container
    .step-heading {
        display: none;
        @include md {
            margin-top: 50px;
            display: block;
            text-align: center;
        }
        .step-heading-text {
            font-size: $font18;
            line-height: $lheight22;
            color: $light-green-color;
        }
    }

    .form-container {
        margin-top: 65px;
        @include md {
            margin-top: 30px;
        }

        .form-field-row {
            margin-bottom: 20px;
            flex-wrap: wrap;
            .form-field {
                margin-right: 25px;
                margin-bottom: 10px;
                &.address-field {
                    max-width: 1065px;
                    width: 100%;
                    @include laptop {
                        max-width: 950px;
                    }
                    @include xl {
                        max-width: 800px;
                    }
                    @include lg {
                        max-width: 620px;
                    }
                    @include md {
                        max-width: 100%;
                    }
                }
                @include lg {
                    margin-right: 20px;
                }
                @include md {
                    width: 100%;
                    margin-right: 0;
                }
                &.phonenumber-panel {
                    .number-prefix {
                        top: 39px;
                        font-size: 14px;
                    }
                }
                .input-panel {
                    display: flex;
                    &.address-panel {
                        .input-block {
                            max-width: 100%;
                            min-width: 100%;
                        }
                    }
                }

                .input-block {
                    max-width: 339px;
                    min-width: 339px;
                    @include laptop {
                        max-width: 300px;
                        min-width: 300px;
                    }
                    @include md {
                        max-width: 100%;
                        min-width: 100%;
                    }
                }

                .tootltip-field {
                    max-width: 100%;
                    flex: 1;
                    min-width: 100%;
                    @include sm {
                        max-width: 90%;
                        min-width: 90%;
                    }
                }
            }
        }
        .select-from-computer {
            .btn-medium {
                width: 150px;
                height: 35px;
            }
        }
    }
}
.status-dropdown {
    width: 100%;
    .input-block {
        max-width: 200px;
        width: 100%;
        margin: 14px 0px;
    }
    .dropdown-block .dropdown .dropdown-toggle {
        @include xl {
            padding: 10px;
        }
    }
}
@include md {
    .form-input.datepicker-block {
        position: relative;
        &:after {
            content: "";
            position: absolute;
            background: url(../../../images/svg-icon/datepicker-icon.svg) no-repeat;
            width: 20px;
            height: 20px;
            right: 22px;
            top: 37px;
            z-index: -1;
        }
    }
}
