.admin-panel-page {
    margin: 80px 0px;
    .admin-data-panel {
        .data-block {
            max-width: 260px;
            width: 100%;
            height: 90px;
            padding: 0 20px;
            background-size: 100% 100% !important;
            margin-right: 30px;
            &.paid-data-block {
                background: url("../../../images/admin-panel/paid-backimg.png") no-repeat;
            }
            &.unpaid-data-block {
                background: url("../../../images/admin-panel/unpaid-backimg.png") no-repeat;
            }
            &.partially-paid-block {
                background: url("../../../images/admin-panel/partially-backimg.png") no-repeat;
            }
            &.new-data-block {
                background: url("../../../images/admin-panel/new-backimg.png") no-repeat;
            }
            .paid-color {
                color: $paid-count-color;
            }
            .unpaid-color {
                color: $unpaid-count-color;
            }
            .partially-paid-color {
                color: $partially-count-color;
            }
            .new-color {
                color: $new-count-color;
            }
            .block-title {
                font-size: $font20;
            }
            .block-count {
                font-size: $font30;
            }
        }
    }
    .filter-panel {
        .filter-dropdown {
            margin-left: 15px;
            .dropdown-block .dropdown .dropdown-toggle {
                background: $light-green-color;
                height: 40px;
                padding: 11px 20px;
                &:after {
                    content: "";
                    background: url("../../../images/svg-icon/filter-white-icon.svg") no-repeat;
                    height: 17px;
                    width: 16px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    border: 0;
                    transform: rotate(0);
                }
            }
            .dropdown-menu {
                right: 0 !important;
                left: auto !important;
                transform: translate(0, 50px) !important;
                max-height: 175px;
            }
        }
    }
    .filter-multiselect-panel {
        position: relative;
        .multiselect-dropdown,.multi-select {
            position: absolute;
            top: 0;
            width: 100%;
            &:focus{
                outline: none;
            }
            
            .dropdown-container {
                position: relative;
                border: 0;
                background: transparent;
                box-shadow: none;
                
                .dropdown-heading {
                    // opacity: 0;
                    outline: none;
                    cursor: pointer;
                    &:before {
                        content: "";
                        background: url("../../../images/svg-icon/filter-icon.svg") no-repeat;
                        height: 17px;
                        width: 16px;
                        right: -25px;
                        position: absolute;
                        top: 3px;
                        background-size: 14px;
                        @include xl {
                            right: -20px;
                            background-size: 12px;
                            width: 12px;
                            height: 12px;
                            top: 4px;
                        }
                    }
                    .dropdown-heading-value,.dropdown-heading-dropdown-arrow,.clear-selected-button{
                        opacity: 0;
                    }
                }
                .dropdown-content{
                    min-width: 180px;
                }
            }
        }
        .cell-header-data {
            position: relative;
            // &:before {
            //     content: "";
            //     background: url("../../../images/svg-icon/filter-icon.svg") no-repeat;
            //     height: 17px;
            //     width: 16px;
            //     right: -30px;
            //     position: absolute;
            //     top: 2px;
            //     @include xl {
            //         right: -20px;
            //         background-size: 12px;
            //         width: 12px;
            //         height: 12px;
            //         top: 4px;
            //     }
            // }
        }
        .multiselect-container {
            position: absolute;
            top: 0;
            .search-wrapper {
                border: 0;
                padding: 0;
                position: relative;

                .chip {
                    display: none;
                }
                input {
                    cursor: pointer;
                    opacity: 0;
                }
            }
            .optionListContainer {
                min-width: 170px;
                width: 100%;
                .optionContainer {
                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                    &::-webkit-scrollbar-track {
                        box-shadow: none;
                        border-radius: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background: $light-green-color;
                    }
                    li {
                        color: $black-color;
                        font-size: $font14;
                        font-family: "NunitoSans-Regular", sans-serif;
                        &:hover,
                        &.highlight:hover {
                            background: $light-gray-color;
                        }
                        &.highlight {
                            background: transparent;
                        }
                    }
                }
            }
        }
    }
    .sorting-panel {
        position: relative;
        cursor: pointer;
        &::after {
            content: "";
            background: url("../../../images/svg-icon/sorting-icon.svg") no-repeat;
            height: 17px;
            width: 16px;
            right: -45px;
            position: absolute;
            top: 2px;
            background-size: 10px;
            @include xl {
                right: -40px;
                background-size: 8px;
                width: 12px;
                height: 12px;
                top: 6px;
            }
        }
    }
    .no-data-panel {
        border: 1px solid $light-gray-color;
        border-radius: 16px;
    }
    .page-dropdown {
        .dropdown-block .dropdown .dropdown-toggle {
            min-width: 90px;
        }
    }
    .admin-table-card {
        &.accountant-table-card {
            .table-header,
            .table-body {
                .table-data-row {
                    .cell {
                        &.cell-name {
                            .cell-body-data{
                                text-transform: capitalize;
                            }
                            @include laptop {
                                width: 15%;
                            }
                            @include lg {
                                width: 30%;
                            }
                            @include sm {
                                width: 68%;
                            }
                            @include mobile {
                                width: 55%;
                            }
                        }
                        &.cell-contact {
                            width: 18%;
                            @include laptop {
                                width: 23%;
                            }
                            @include xl {
                                width: 28%;
                            }
                            @include lg {
                                width: 50%;
                            }
                            @include md {
                                width: 47%;
                            }
                        }
                        &.cell-app-id {
                            width: 14%;
                            @include laptop {
                                width: 15%;
                            }
                            @include xl {
                                width: 20%;
                            }
                        }
                        &.cell-location {
                            width: 15%;
                            @include laptop {
                                width: 16%;
                            }
                            @include xl {
                                width: 22%;
                            }
                        }
                        &.cell-app-date {
                            width: 15%;
                            @include laptop {
                                width: 16%;
                            }
                        }
                        &.cell-application {
                            width: 16%;
                        }
                    }
                }
            }
        }
        .table-body {
            max-height: 800px;
            overflow-y: auto;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                box-shadow: none;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: $light-green-color;
            }
        }
        .table-header,
        .table-body {
            margin-bottom: 25px;
            position: relative;
            .table-data-row {
                display: flex;
                align-items: center;
                background: $table-background-color;
                padding: 20px 35px;
                border-radius: 16px;
                @include md {
                    padding: 15px 25px;
                }
                &.table-body-data-row {
                    border: 1px solid $light-gray-color;
                    background: $white-color;
                    margin-bottom: 15px;
                    padding: 8px 35px;
                    @include md {
                        padding: 10px 25px;
                    }
                }
                .cell {
                    &.cell-arrow {
                        display: none;
                        @include laptop-max {
                            display: block;
                            width: 5%;
                        }
                        @include sm {
                            width: 10%;
                        }
                        @include mobile {
                            width: 20%;
                        }
                    }
                    &.cell-name {
                        width: 12%;
                        .cell-body-data{
                            text-transform: capitalize;
                        }
                        @include laptop-max {
                            width: 10%;
                        }
                        @include laptop {
                            width: 14%;
                        }
                        @include xl {
                            width: 20%;
                        }
                        @include lg {
                            width: 35%;
                        }
                        @include md {
                            width: 32%;
                        }
                        @include sm {
                            width: 68%;
                        }
                        @include mobile {
                            width: 55%;
                        }
                    }
                    &.cell-app-id {
                        width: 12%;
                        @include laptop-max{
                            width: 14%;
                        }
                        @include laptop{
                            width: 17%;
                        }
                        @include xl {
                            width: 18%;
                        }
                        @include lg {
                            display: none;
                        }
                    }
                    &.cell-app-date {
                        width: 12%;
                        @include laptop-max {
                            width: 16%;
                        }
                        @include laptop {
                            width: 18%;
                        }
                        @include xl {
                            display: none;
                        }
                    }
                    &.cell-contact {
                        width: 16%;
                        @include laptop-max {
                            width: 18%;
                        }
                        @include laptop {
                            width: 22%;
                        }
                        @include xl {
                            width: 30%;
                        }
                        @include lg {
                            width: 45%;
                        }
                        @include sm {
                            display: none;
                        }
                    }
                    &.cell-location {
                        width: 12%;
                        @include laptop {
                            width: 16%;
                        }
                        @include xl {
                            width: 20%;
                        }
                        @include lg {
                            display: none;
                        }
                    }
                    &.cell-payment {
                        width: 12%;
                        @include laptop-max {
                            display: none;
                        }
                    }
                    &.cell-application {
                        width: 14%;
                        @include laptop-max {
                            width: 17%;
                        }
                        @include laptop {
                            display: none;
                        }
                    }
                    &.cell-action {
                        width: 10%;
                        @include laptop-max{
                            width: 8%;
                        }
                        @include lg {
                            width: 15%;
                        }
                        @include md {
                            width: 18%;
                        }
                        @include sm {
                            width: 22%;
                        }
                        @include mobile {
                            width: 25%;
                        }
                    }
                    .droparrow {
                        cursor: pointer;
                        border: solid $light-green-color;
                        border-width: 0 2px 2px 0;
                        display: inline-block;
                        padding: 5px;
                        transform: rotate(-45deg);
                        -webkit-transform: rotate(-45deg);
                        @include transition;
                        &.active {
                            transform: rotate(45deg);
                            -webkit-transform: rotate(45deg);
                        }
                    }
                    .action-btn {
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                        img {
                            width: 100%;
                            height: 100%;
                        }
                        &.edit-btn,
                        &.view-btn,
                        &.assign-btn {
                            margin-right: 20px;
                            @include xl {
                                margin-right: 15px;
                            }
                            @include sm {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
            .cell-header-data {
                font-size: $font16;
                line-height: $lheight20;
                color: $black-color;
                @include xl {
                    font-size: $font14;
                    line-height: $lheight18;
                }
            }
            .cell-body-data {
                font-size: $font14;
                line-height: $lheight20;
                color: $dark-gray-color;            
            }
            .cell-body-subdata {
                font-size: $font14;
                line-height: $lheight20;
                color: $light-gray-color;
            }
            .color-label {
                width: 105px;
                padding: 8px 0px;
                text-align: center;
                @include lg {
                    width: 90px;
                }
                .label-text {
                    font-size: $font14;
                    line-height: $lheight18;
                }
            }
            .application-status-panel {
                width: 125px;
                border-radius: 7px;
                padding: 8px 0px;
                .application-status {
                    font-size: $font14;
                    line-height: $lheight18;
                }
            }

            .table-card {
                max-width: 170px;
                background-color: $white-color;
                top: 60px;
                left: 40px;
                border: 0;
                box-shadow: none;
                border-radius: 8px;
                margin-top: 5px;
                padding: 0px 15px;
                width: 100%;
                z-index: 9;
                border: 1px solid $light-gray-color;
                position: absolute;
                display: none;
                @include laptop-max {
                    display: block;
                }
                @include sm {
                    left: 30px;
                    top: 40px;
                }
                .table-card-item {
                    align-items: center;
                    padding: 15px 0px;
                    border-bottom: 1px solid $light-gray-color;
                    display: none;
                    &:last-child {
                        border-bottom: none;
                    }
                    &.cell-application {
                        @include laptop {
                            display: block;
                        }
                    }
                    &.cell-payment {
                        @include laptop-max {
                            display: block;
                        }
                    }
                    &.cell-location {
                        @include lg {
                            display: block;
                        }
                    }
                    &.cell-app-id {
                        @include lg {
                            display: block;
                        }
                    }
                    &.cell-app-date {
                        @include xl {
                            display: block;
                        }
                    }
                    &.cell-contact {
                        @include sm {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    //pagination-block
    .pagination-block {
        display: flex;
        justify-content: flex-end;
        margin: 0px 20px;
        .pagination {
            background: $pagination-background;
            padding: 15px 20px;
            border-radius: 7px;
            display: flex;
            position: relative;
            margin-bottom: 0;
            @include sm {
                padding: 15px 5px;
            }
            .left-arrow {
                a {
                    border: solid $light-green-color;
                    border-width: 0 1px 1px 0;
                    display: inline-block;
                    padding: 5px;
                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                }
            }
            .right-arrow {
                a {
                    border: solid $light-green-color;
                    border-width: 0 1px 1px 0;
                    display: inline-block;
                    padding: 5px;
                    transform: rotate(-45deg);
                    -webkit-transform: rotate(-45deg);
                }
            }
            .number-block {
                margin: 0px 15px;
                cursor: pointer;
                @include sm {
                    margin: 0 10px;
                }
                a {
                    color: $light-gray-color;
                    font-size: $font16;
                    line-height: $lheight20;
                    @include sm {
                        font-size: $font14;
                    }
                }
                &:hover,
                &.active {
                    a {
                        color: $light-green-color;
                    }
                }
            }
        }
    }
}
