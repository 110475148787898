// mobile devices
@mixin mobile {
    @media (max-width: #{$screen-mobile-max}) {
        @content;
    }
}

// Small devices
@mixin sm {
    @media (max-width: #{$screen-sm-max}) {
        @content;
    }
}

@mixin sm-min {
    @media (max-width: #{$screen-sm-min}) {
        @content;
    }
}
 
// Medium devices
@mixin md {
    @media (max-width: #{$screen-md-max}) {
        @content;
    }
}
 
// Large devices
@mixin lg {
    @media (max-width: #{$screen-lg-max}) {
        @content;
    }
}

// Large devices
@mixin lg-min {
    @media (max-width: #{$screen-lg-min}) {
        @content;
    }
}
 
// Extra large devices
@mixin xl {
    @media (max-width: #{$screen-xl-max}) {
        @content;
    }
}

@mixin xl-min {
    @media (max-width: #{$screen-xl-min}) {
        @content;
    }
}

// laptop devices
@mixin laptop {
    @media (max-width: #{$screen-laptop}) {
        @content;
    }
}

@mixin laptop-max {
    @media (max-width: #{$screen-laptop-max}) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: #{$screen-desktop}) {
        @content;
    }
}

@mixin xl-lg {
    @media (max-width: #{$screen-xl-max}) and (min-width: #{$screen-lg-max}) {
        @content;
    }
}