.owl-theme {
    .owl-dots {
        position: absolute;
        left: 0;
        right: 0;
        bottom: -40px;
        @include md{
            bottom: -30px;
        }
        .owl-dot {
            &:hover {
                span {
                    background: $light-green-color !important;
                }
            }
            &.active {
                span {
                    // background: $light-green-color !important;
                    // box-shadow: inset 0px 0px 0px 2px $white-color;
                    position: relative;
                    &:before{
                        border-color: $light-green-color;
                    }
                    
                }
            }
            span {
                background: $light-green-color !important;
                border: 1px solid $light-green-color !important;
                width: 12px !important;
                height: 12px !important;
                &:before{
                    content: '';
                    position: absolute;
                    left: -4px;
                    top: -4px;
                    border: 1px solid transparent;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                }
            }
        }
    }
}