.login-signup-page {
    overflow: hidden;
    .left-panel {
        background-image: $green-gradiant-color;
        height: 100vh;
        position: relative;
        padding: 0 20px;
        @include md {
            height: 60vh;
        }
        &::before {
            content: "";
            position: absolute;
            background: url("../../../../component/images/login-signup/green-lotus-design.png") no-repeat;
            left: -2px;
            top: -2px;
            width: 300px;
            height: 200px;
            background-size: 300px;
            @include transition;
            @include md {
                background-size: 200px;
                width: 200px;
                height: 140px;
            }
            @include sm {
                background-size: 150px;
                width: 150px;
                height: 100px;
            }
        }
    }

    .right-panel {
        position: relative;
        height: 100vh;
        padding: 50px 30px;
        overflow-y: auto;
        overflow-x: hidden;
        @include md {
            height: auto;
            padding: 50px 10px;
        }
        &::after {
            content: "";
            position: fixed;
            background: url("../../../../component/images/login-signup/gray-lotus-design.png") no-repeat;
            right: -2px;
            bottom: 0;
            width: 350px;
            height: 220px;
            background-size: 350px;
            z-index: -1;
            @include md {
                // content: none;
                background-size: 200px;
                width: 200px;
                height: 120px;
            }
        }
        .form-block {
            max-width: 600px;
            width: 100%;
            margin: 0 auto;
            // @include xl {
            //     margin: 0px 20px;
            //     max-width: 100%;
            // }

            .form-group {
                margin-top: 35px;
                .form-input {
                    height: 50px;
                    border-radius: 30px;
                }
            }
            .password-input-panel {
                .visibility-icon {
                    position: absolute;
                    right: 20px;
                    top: 45px;
                    z-index: 9;
                    cursor: pointer;
                    &.show-eye-icon {
                        display: none;
                    }
                }
                &.show-password {
                    .show-eye-icon {
                        display: block;
                    }
                    .hide-eye-icon {
                        display: none;
                    }
                }
            }
            .input-group {
                border: 1px solid $light-gray-color;
                border-radius: 30px;
                .form-input {
                    border: 0;
                }
                .input-group-prepend {
                    max-width: 60px;
                    width: 100%;
                    .input-group-text {
                        background: transparent;
                        border: 0;
                        width: 100%;
                        justify-content: center;
                    }
                }
            }
            .otp-input-panel {
                margin-top: 15px;
                .otp-input {
                    max-width: 60px;
                    width: 2em !important;
                    height: 60px;
                    border-radius: 14px;
                    padding: 0 0px;
                    margin: 0 10px;
                    text-align: center;
                    font-size: $font25;
                    @include lg {
                        padding: 0 15px;
                    }
                    @include sm {
                        width: 40px;
                        height: 40px;
                        padding: 0 10px;
                        font-size: $font18;
                        margin: 0 5px;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}
.phonenumber-panel {
    position: relative;
    &#profile {
        .number-prefix {
            top: 10px;
        }
    }
    .number-prefix {
        position: absolute;
        left: 20px;
        top: 43px;
    }
    .input-block {
        .form-input {
            padding-left: 60px !important;
        }
    }
}
.back-to-home-text {
    position: absolute;
    right: 40px;
    top: 10px;
    z-index: 9;
    @include transition;
    padding-right: 15px;
    &:hover {
        color: $light-green-color;
    }
    &:after {
        content: "";
        background: url("../../../images/svg-icon/back-home.svg") no-repeat;
        width: 15px;
        height: 16px;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: -10px;
    }
}
