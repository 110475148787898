.container-block {
    max-width: 85%;
    margin: 0 auto;
    &.small-container-block {
        max-width: 70%;
        @include xl {
            max-width: 85%;
        }
    }
    @include sm {
        max-width: 90%;
    }
}
.full-container-block {
    max-width: 100%;
}
.section-padding {
    padding: 50px 0;
    @include md {
        padding: 30px 0;
    }
}
.section-heading-panel {
    @include lg {
        text-align: center !important;
    }
}
.preheading-text {
    font-size: $font20;
    color: $light-green-color;
    letter-spacing: 15px;
    @include xl {
        font-size: $font18;
        letter-spacing: 10px;
    }
    @include md {
        font-size: $font16;
        line-height: $lheight24;
    }
}
.heading-text {
    font-size: $font30;
    color: $light-gray-color;
    max-width: 350px;
    width: 100%;
    line-height: $lheight34;
    @include lg {
        margin: 0 auto !important;
    }
    @include md {
        font-size: $font25;
    }
}
.description-text {
    line-height: $lheight24 !important;
}

.col-order-12 {
    @include lg {
        order: 12;
    }
}
.col-order-1 {
    @include lg {
        order: 1;
    }
}

.display-lg-none {
    @include lg {
        display: none !important;
    }
}

.display-lg-block {
    display: none !important;
    @include lg {
        display: block !important;
    }
}

.heading-panel {
    .heading-panel-text {
        font-size: $font30;
        line-height: $lheight34;
        @include xl {
            font-size: $font20;
            line-height: $lheight24;
        }
        @include md {
            font-size: $font18;
            line-height: $lheight22;
        }
        @include mobile {
            font-size: $font16;
            line-height: $lheight20;
        }
    }
}
.subheading-panel {
    .subheading-panel-text {
        font-size: $font20;
        line-height: $lheight24;
        color: $light-gray-color;
        @include xl {
            font-size: $font18;
            line-height: $lheight22;
        }
        @include md {
            font-size: $font16;
            line-height: $lheight20;
        }
    }
}

.color-label {
    padding: 8px 30px;
    border-radius: 6px;
    width: max-content;
    @include sm {
        padding: 8px 20px;
    }
    &.green-label {
        background-color: $label-light-green-color !important;
        color: $label-green-color !important;
        .label-text {
            color: $label-green-color;
        }
    }
    &.red-label {
        background-color: $light-red-color !important;
        color: $red-color !important;
        .label-text {
            color: $red-color;
        }
    }
    &.partial-paid-label {
        background-color: $partial-paid-bg !important;
        color: $partial-paid-text !important;
        .label-text {
            color: $partial-paid-text;
        }
    }
}

//application-status
.application-status-panel {
    height: 100%;
    text-align: center;
    padding: 10px;
    align-items: center;
    justify-content: center;
    display: flex;
    .application-status {
        font-size: $font16;
        line-height: $lheight20;
        @include laptop {
            font-size: $font14;
            line-height: $lheight18;
        }
    }
}
.dropdown-block .dropdown .dropdown-toggle,
.application-status-panel, .color-label {
    
    &.new {
        background-color: $gray-shade-color !important;
        color: $dark-gray-color !important;
        border-color: $gray-shade-color !important;
        .application-status {
            color: $dark-gray-color !important;
        }
    }
    &.assigned {
        background-color: $label-light-pink-color !important;
        color: $label-pink-color !important;
        border-color: $label-light-pink-color !important;
        .application-status {
            color: $label-pink-color !important;
        }
    }
    &.confirmed {
        background-color: $label-light-green-color !important;
        color: $label-green-color !important;
        border-color: $label-light-green-color !important;
        .application-status {
            color: $label-green-color !important;
        }
    }
    &.in-process {
        background-color: $label-light-yellow-color !important;
        color: $label-yellow-color !important;
        border-color: $label-light-yellow-color !important;
        .application-status {
            color: $label-yellow-color !important;
        }
    }
    &.ready-review {
        background-color: $label-light-blue-color !important;
        color: $label-blue-color !important;
        border-color: $label-light-blue-color !important;
        .application-status {
            color: $label-blue-color !important;
        }
    }
    &.cancel{
        background-color: $label-light-red-color !important;
        color: $label-red-color !important;
        border-color: $label-light-red-color !important;
        .application-status {
            color: $label-red-color !important;
        }
    }
    &.on-hold{
        background-color: $label-light-orange-color !important;
        color: $label-orange-color !important;
        border-color: $label-light-orange-color !important;
        .application-status {
            color: $label-orange-color !important;
        }
    }
    &.submit-cra {
        background-color: $label-light-brown-color !important;
        color: $label-brown-color !important;
        border-color: $label-light-brown-color !important;
        .application-status {
            color: $label-brown-color !important;
        }
    }
    &.ready-submit {
        background-color: $label-light-purple-color !important;
        color: $label-purple-color !important;
        border-color: $label-light-purple-color !important;
        .application-status {
            color: $label-purple-color !important;
        }
    }
}

//bullet-point
.features-block {
    .bullet-block {
        padding-left: 40px;
        position: relative;
        margin-bottom: 20px;
        @include md {
            margin-bottom: 10px;
        }
        &::before {
            content: "";
            background: url("../../images/svg-icon/bullet-check-icon.svg");
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
            top: 0;
        }
        .bullet-title {
            .bullet-title-text {
                font-size: $font16;
                line-height: $lheight20;
                color: $black-color;
                @include md {
                    font-size: $font14;
                    line-height: $lheight18;
                }
            }
        }
        .bullet-desc {
            .bullet-desc-text {
                font-size: $font16;
                line-height: $lheight20;
                color: $light-gray-color;
                @include md {
                    font-size: $font14;
                    line-height: $lheight18;
                }
            }
        }
    }
}

.no-data-panel {
    height: 500px;
    flex-direction: column;
    &.no-document-panel{
        border: 1px solid $light-gray-color;
        padding: 20px;
    }
}

.cursor-pointer {
    cursor: pointer;
}
.bullet-point{   
    .bullet-point-text{
        position: relative;
        display: block;
        font-style: italic;
        // &::before{
        //     position: absolute;
        //     content: '';
        //     background-color: $light-green-color;
        //     width: 11px;
        //     height: 11px;
        //     border-radius: 50%;            
        //     left: 0;
        //     margin: 5px auto;
        // }
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

// btn tooltip
.icon-panel{
    position: relative;
    .btn-tooltip{
        display: none;
        border-radius: 3px;
        width: max-content;
        top: 30px;
        box-shadow: 1px 1px 6px rgba(0,0,0,0.16);
        position: absolute;
        background-color: $gray-shade-color;           
        z-index: 1;
        border: 1px solid $light-gray-color;
        padding: 5px;
        transition: 300ms ease-in-out;
        left: 0;
        right: 0;
        margin: 0 auto;
        .btn-tooltip-text{       
            font-size: $font12;
            line-height: $lheight16;
            display: block;
            font-family: "NunitoSans-SemiBold", sans-serif;           
        }      
    }
    &:hover{
        .btn-tooltip{
            display: block;
        } 
    }
}