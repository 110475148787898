.icon{
    &.disabled{
        opacity: 0.5 !important;
        cursor: not-allowed;
        pointer-events: none;
    }
}
.view-application-page {
    margin: 50px 0px;
    .btn-create-application {
        height: 42px;
    }
    
    .applicatant-info-panel {
        @include sm {
            display: block !important;
        }
        .applicant-sin {
            margin-left: 200px;
            @include lg {
                margin-left: 100px;
            }
            @include sm {
                margin-left: 0px;
                margin-top: 20px;
            }
        }
    }
    .application-container {
        margin-top: 50px;
        .application-info-block {
            border-radius: 12px;
            margin-bottom: 30px;
            overflow: hidden;
            box-shadow: 1px 1px 10px rgba(154, 170, 207, 0.2);
            cursor: pointer;
            @include transition;
            .notification-info {
                position: absolute;
                right: 10px;
                top: -5px;
                background: $label-red-color;
                border-radius: 50%;
                width: 20px;
                height: 20px;
                z-index: 9;
                text-align: center;
            }
            &:hover {
                box-shadow: 0px 16px 40px rgba(154, 170, 207, 0.2);
            }
            .left-panel {
                padding: 25px;
                @include laptop {
                    padding: 15px;
                }
                .data-block {
                    .application-info-header {
                        .application-info-header-text {
                            font-size: $font16;
                            line-height: $font20;
                            color: $light-gray-color;
                            @include laptop {
                                font-size: $font14;
                                line-height: $lheight18;
                            }
                        }
                    }
                    .application-data-panel {
                        margin-top: 5px;
                        .application-data {
                            font-size: $font16;
                            line-height: $font20;
                            color: $black-color;
                            @include laptop {
                                font-size: $font14;
                                line-height: $lheight18;
                            }
                        }
                    }
                }
            }
        }
    }
}
