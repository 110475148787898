.update-payment-popup{
    .MuiDialog-container {
        .MuiPaper-root{
            .MuiDialogTitle-root{
                padding: 20px 45px 16px 24px;
                @include md{
                    padding: 10px 45px 10px 14px;
                }
                .heading-title{
                    span{
                        display: block;                       
                    }
                }
                .cancel-btn{
                    top: 12px;
                    @include md{
                        top: 10px;
                        right: 5px;
                    }
                }
            }
            .MuiDialogContent-root{
                padding: 10px 25px 25px 25px;
                @include md{
                    padding: 10px 15px 15px 15px;
                }
                .form-group{
                    margin-top: 15px;
                    .form-input{
                        border-radius: 6px;
                    }
                }
            }
        }

    }   

}