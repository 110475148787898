.admin-table-card.admin-subscription-card {
    .table-header,
    .table-body {
        .table-data-row {
            .cell {
                &.cell-id{
                    width: 45%;
                    @include lg{
                        width: 43%;
                    }
                    @include md{
                        width: 42%;
                    }
                    @include sm{
                        width: 68%;
                    }
                    @include mobile{
                        width: 60%;
                    }
                }
                &.cell-date {
                    width: 45%;
                    @include lg{
                        width: 42%;
                    }
                    @include md{
                        width: 40%;
                    }
                    @include sm{
                        display: none;
                    }
                }
                &.cell-arrow{
                    display: none;
                    @include sm {
                        display: block;
                    }
                    @include mobile{
                        width: 15%;
                    }
                }
            }
        }
        .table-card {
            display: none;
            @include sm {
                display: block;
            }
            .table-card-item {
                &.cell-date {
                    @include sm {
                        display: block;
                    }
                }
            }
        }
    }
}
