
.d-block {
    display: block !important;
}

.d-none {
    display: none;
}

.d-inline-block {
    display: inline-block !important;
}

//flex classes

.d-flex {
    display: flex !important;
}
.align-item-end{
    align-items: flex-end;
}
.align-item-start{
    align-items: flex-start;
}
.center {
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.flex1{
    flex: 1;
}
.justify-content-between{
    justify-content: space-between !important;
}

.align-items-center{
    align-items: center !important;
}

.image-responsive{
    max-width: 100%;
    margin: 0 auto;
}