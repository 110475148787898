.admin-table-card.references-table-card {
    .table-header,
    .table-body {
        .table-data-row {
            .cell {
                &.cell-arrow{
                    @include lg{
                        display: block;
                    }
                }
                &.cell-app-id{
                    width: 20%;
                    display: block;
                    @include lg{
                        width: 22%;
                    }
                    @include md{
                        width: 35%;
                    }
                    @include sm{
                        display: none;
                    }
                }
                &.cell-applicant{
                    width: 22%;
                    @include lg{
                        width: 26%;
                    }
                    @include md{
                        width: 35%;
                    }
                    @include sm{
                        width: 45%;
                    }
                }
                &.cell-reference-name{
                    width: 21%;
                    @include lg{
                        width: 26%;
                    }
                    @include md{
                        width: 25%;
                    }
                    @include sm{
                        width: 40%;
                    }
                    @include mobile{
                        width: 45%;
                    }
                }
                &.cell-reference-email{
                    width: 21%;
                    @include md{
                        display: none;
                    }
                }
                &.cell-reference-date{
                    width: 16%;
                    @include lg{
                        display: none;
                    }
                }
            }
        }
        .table-card {
            display: none;
            @include lg {
                display: block;
                top: 35px;
                left: 35px;
            }
            .table-card-item {
                &.cell-reference-date {
                    @include lg {
                        display: block;
                    }
                }
                &.cell-reference-date{
                    @include md{
                        display: block;
                    }
                }
                &.cell-app-id{
                    @include sm{
                        display: block;
                    }
                }
            }
        }
    }
}
