.payment-plans {
    margin: 70px 0px;    
    .category-block {
        .heading-block {
            .subheading-panel,
            .heading-panel {
                margin-bottom: 10px;
            }
        }
        .categories-container {
            display: flex;
            margin-top: 50px;
            .container-block{
                @include md{
                    max-width: 100%;
                }
            }
            .categories-panel {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .select-category-block {
                    border: 1px solid $light-gray-color;
                    max-width: 280px;
                    min-width: 280px;
                    margin: 8px;
                    padding: 20px;
                    border-radius: 5px;
                    cursor: pointer;
                    @include transition;
                    @include laptop-max{
                        max-width: 240px;
                        min-width: 240px;
                    }
                    @include laptop{
                        max-width: 200px;
                        min-width: 200px;
                    }
                    @include xl{
                        max-width: 160px;
                        min-width: 160px;
                    }
                    @include lg{
                        max-width: 250px;
                        min-width: 250px;
                    }
                    @include md{
                        max-width: 220px;
                        min-width: 220px;
                    }
                    @include sm{
                        max-width: 150px;
                        min-width: 150px;
                    }
                    @include mobile{
                        margin: 4px;
                        padding: 5px;
                        max-width: 120px;
                        min-width: 120px;
                    }
                   
                    &.active {
                        // transform: scale(1.1);
                        border: 1px solid $light-green-color;
                        position: relative;
                        @include box-shadow;
                        &::before {
                            content: "";
                            background: url("../../../images/svg-icon/verification-icon.svg");
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            background-size: 100%;
                            left: -15px;
                            top: -15px;
                        }
                        .title-block {
                            .title-block-text {
                                color: $black-color;
                            }
                        }
                    }
                    .image-block {
                        margin-top: 30px;
                    }
                    .title-block {
                        margin-top: 30px;
                        @include md{
                            margin-top: 15px;
                        }
                        .title-block-text {
                            font-size: $font12;
                            line-height: $font16;
                            color: $light-gray-color;
                            @include md{
                                font-size: $font10;
                                line-height: $font14;
                            }
                        }
                    }
                }
            }
        }
    }
    .plan-section {
        margin-top: 50px;
        .owl-carousel .owl-stage-outer{
            padding: 40px 0;
        }
        .plans-panel{
            margin-top: 30px;
            padding: 0 20px;
            .plan-block{                
                border: 1px solid $light-gray-color;
                padding: 0px 50px;
                border-top: 10px solid $yellow-color;
                position: relative;
                height: 570px;
                @include transition;
                @include laptop{
                    height: 620px;
                    padding: 0px 30px;
                }
                @include xl{
                    height: 650px;
                    padding: 0px 15px;
                }
                @include lg{
                    height: 600px;
                }
                @include md{
                    height: 550px;
                }
                @include sm{
                    height: 500px;
                }
                @include mobile{
                    height: 600px;
                }
                &:hover, &.active{
                    border-top: 10px solid $red-color;
                    //transform: scale(1.05);
                    box-shadow: 1px 1px 20px rgba(0,0,0,0.39);
                }                
                   
                .recommend-block-text{
                    position: absolute;
                    top: -10px;
                    width: 100%;
                    text-align: center;
                    left: 0;
                    margin: 0 auto;
                    right: 0;
                    padding: 10px;
                    background-color: $red-color;
                    text-transform: uppercase;
                    font-size: $font16;
                    line-height: $lheight20;
                    color: $white-color;                        
                }
               
                .plan-title{
                    margin-top: 30px;
                    padding: 20px 0px;
                    border-bottom: 1px solid $light-gray-color;
                    .plan-title-text{
                        font-size: $font20;
                        line-height: $lheight24;
                        color: $light-gray-color;
                        text-transform: uppercase;
                        letter-spacing: 5px;
                        @include xl{
                            font-size: $font18;
                            line-height: $lheight22;
                        }
                        @include md{
                            font-size: $font16;
                            line-height: $lheight20;
                        }
                    }
                }
                .pricing-panel{
                    padding: 10px 0px;
                    border-bottom: 1px solid $light-gray-color;
                    .pricing-panel-text{
                        font-size: $font20;
                        line-height: $lheight24;
                        color: $black-color;
                        text-transform: uppercase;
                        letter-spacing: 5px;
                        @include xl{
                            font-size: $font18;
                            line-height: $lheight22;
                        }
                        @include md{
                            font-size: $font16;
                            line-height: $lheight20;
                        }
                    }
                }
                .subheading-panel{
                    margin: 20px;
                }               
                .plan-btn{
                    position: absolute;
                    right: 0;
                    left: 0;
                    margin: 0 auto;
                    bottom: -30px;
                    text-align: center;
                    @include md{
                        bottom: -19px;
                    }
                }
            }
        }
    }
    .make-payment-btn{
        margin-top: 100px;
    }
}
