.tootltip-container {
    position: relative;
    .tootltip-icon {
        cursor: pointer;
        &:hover {
            .tooltip-text {
                display: block;
            }
        }
        .tooltip-text {
            position: absolute;
            right: -13px;
            top: 40px;
            border-radius: 6px;
            display: none;
            border: 1px solid $light-gray-color;
            background: $white-color;
            z-index: 9;
            padding: 5px 10px;
            width: 220px;           
            .tooltip-text-panel {
                color: $black-color;
                font-size: $font14;
                line-height: $lheight18;
            }
            &:before {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-top: 7px solid transparent;
                border-right: 7px solid $light-gray-color;
                border-bottom: 7px solid transparent;
                transform: rotate(90deg);
                right: 17px;
                top: -11px;              
            }
    
            &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-right: 6px solid #ffffff;
                border-bottom: 6px solid transparent;
                transform: rotate(90deg);
                right: 17px;
                top: -9px;
               
            }
            &.secondary-tootltip{
                right: auto;
                left: -13px;
                &::after{
                    right: auto;
                    left: 17px;
                }
                &::before{
                    right: auto;
                    left: 17px;

                }
            }
        }
    }
}