//banner-css
.banner-mobile-section {
    @include sm {
        height: 350px !important;
    }
}
.banner-section {
    @include lg {
        background: $banner-back-color !important;
    }
    &.tax-banner-section{
        height: 750px;
        @include lg {
            height: 500px;
        }
        @include md {
            height: 450px;
        }
        .banner-wave-panel{
            bottom: -10%;
            @include laptop-max{
                bottom: -7%;
            }
            @include laptop{
                bottom: -4%;
            }
        }
        .banner-carousel .banner-slider-img-block{
            height: 750px;
            @include lg {
                height: 480px;
            }
        }
    }
}
.banner-section,
.banner-mobile-section {
    background-position: bottom right;
    background-size: cover;
    position: relative;
    height: 660px;
    @include lg {
        background: $banner-back-color;
        height: 450px;
    }
    @include md {
        overflow: hidden;
    }
    .banner-social-panel {
        margin-bottom: -70px;
        position: relative;
        z-index: 9;
        @include laptop {
            margin-bottom: -30px;
        }
        @include lg {
            max-width: 100%;
            margin: 0 auto;
            position: absolute;
            bottom: -140px;
            left: 30%;
        }
        @include md {
            left: 35%;
        }
        @include sm {
            bottom: -100px;
        }
        @include mobile {
            bottom: -70px;
            .mr-20 {
                margin-right: 10px;
            }
        }
    }
    @keyframes bannerBankerAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .banner-wave-panel {
        position: absolute;
        bottom: -22%;
        left: 0;
        z-index: 9;
        width: 100%;
        @include laptop-max{
            bottom: -20%;
        }
        @include laptop{
            bottom: -18%;
        }
        @include xl {
            bottom: -16%;
        }
        @include lg {
            bottom: -10%;
        }
        @include md {
            display: none;
        }
    }
    .slant-img-panel {
        position: absolute;
        bottom: -30px;
        animation: slantAnimation 3s forwards;
        width: 78%;
        left: 0;
        z-index: 9;
        @include desktop {
            left: -9%;
        }
        @include laptop-max {
            left: -16%;
        }
        @include laptop {
            left: -25%;
        }
        @include lg {
            bottom: auto;
            width: 100%;
            left: 0;
            height: 450px;
            top: -112px;
            img {
                height: 100%;
            }
        }
        @include sm {
            height: 360px;
        }
    }
    .lotus-pattern-panel {
        position: absolute;
        right: 0;
        bottom: -10%;
        z-index: 99;
        max-width: 15%;
        width: 100%;
        // animation: bannerDescAnimation 3s forwards;
        // animation-delay: 1s;
        @include lg {
            bottom: -20px;
            max-width: 20%;
        }
        @include sm {
            max-width: 30%;
        }
    }
    .banner-banker-panel {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 35%;
        bottom: -30px;
        animation: bannerBankerAnimation 2s forwards;
        animation-delay: 2s;
        opacity: 0;
        z-index: 9;
        @include laptop-max {
            max-width: 42%;
        }
        @include laptop {
            max-width: 35%;
        }
        @include xl {
            max-width: 40%;
        }
        @include lg {
            bottom: 0;
            left: -90px;
            right: auto;
            max-width: 52%;
            animation: none;
            opacity: 1;
        }
        @include md {
            max-width: 60%;
        }
        @include sm {
            max-width: 68%;
        }
        @include sm-min {
            max-width: 80%;
        }
        @include mobile {
            left: -90px;
            max-width: 95%;
        }
    }
    @keyframes bannerDescAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    .banner-description {
        animation: bannerDescAnimation 3s forwards;
        animation-delay: 1s;
        max-width: 70%;
        opacity: 0;
        z-index: 9;
        @include lg {
            padding-left: 0 !important;
            text-align: center;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            max-width: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            animation: none;
            opacity: 1;
        }
        .banner-heading {
            @include laptop {
                font-size: $font40;
                line-height: $lheight44;
            }
            @include xl {
                font-size: $font25;
                line-height: $lheight30;
            }
            @include mobile {
                font-size: $font20;
                line-height: $lheight28;
            }
        }
        .slider-subheading {
            font-size: $font25;
            line-height: $lheight30;
            @include sm {
                font-size: $font20;
                line-height: $lheight24;
            }
        }
        .banner-subheading-panel {
            .btn-primary {
                z-index: 9999;
                position: relative;
            }
            .banner-subheading {
                line-height: $lheight24;
                @include laptop {
                    font-size: $font18;
                    line-height: $lheight24;
                    margin: 20px 0 !important;
                }
                @include sm {
                    font-size: $font14;
                    line-height: $lheight18;
                }
            }

            .btn-large {
                @include xl {
                    width: 180px;
                    height: 45px;
                }
                @include sm {
                    width: 150px;
                    height: 35px;
                }
            }
            .banner-feature-list {
                .banner-feature-list-item {
                    position: relative;
                    padding-left: 50px;
                    margin-bottom: 30px;
                    @include lg {
                        text-align: left;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        background: url("../../images/svg-icon/check.svg") no-repeat;
                        width: 36px;
                        height: 34px;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
    }
    .banner-carousel {
        overflow: hidden;
        background: $banner-back-color;
        .banner-slider-img-block {
            height: 700px;
            &.price-plan-slider-imgblock {
                max-width: 70%;
                margin: 0 auto;
                padding: 50px 0;
                .package-img{
                    cursor: pointer;
                }
                @include laptop-max{
                    max-width: 82%;
                }
                @include laptop{
                    max-width: 90%;
                }
                @include lg{
                    max-width: 85%;
                }
                .banner-img-block{
                    @include lg{
                        display: block;
                    }
                }
            }
            @include lg {
                height: 480px;
            }
            // @include laptop {
            //     height: 400px;
            // }
            // @include xl {
            //     height: 340px;
            // }
            .banner-img-block {
                @include lg {
                    display: none;
                }
                img {
                    height: 100%;
                    object-fit: cover;
                    @include xl {
                        object-position: 38%;
                    }
                }
            }

            .slider-description-panel {
                position: absolute;
                top: 20%;
                left: 0;
                width: 100%;
                @include xl {
                    top: 30%;
                }
                @include lg {
                    position: relative;
                    top: 25%;
                }
                &.left-description-panel {
                    left: 10%;
                    @include lg {
                        left: 0;
                        top: 10%;
                    }
                    &.last-description-panel {
                        @include lg {
                            top: 25%;
                        }
                    }
                }
            }
        }
        .owl-nav {
            .owl-prev {
                position: absolute;
                top: 40%;
                left: 2%;
                height: 30px;
                width: 30px;
                background: $white-alpha-color;
                padding: 0;
                margin: 0;
                @include transition;
                &:hover {
                    background: $white-alpha-color;
                }
                &:before {
                    content: "";
                    background: url("../../images/svg-icon/banner-slider-left-arrow.svg") no-repeat;
                    width: 7px;
                    height: 15px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            .owl-next {
                position: absolute;
                top: 40%;
                right: 2%;
                height: 30px;
                width: 30px;
                background: $white-alpha-color;
                padding: 0;
                margin: 0;
                @include transition;
                &:hover {
                    background: $white-alpha-color;
                }
                &:before {
                    content: "";
                    background: url("../../images/svg-icon/banner-slider-right-arrow.svg") no-repeat;
                    width: 7px;
                    height: 15px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    background-size: 7px;
                }
            }
        }
    }
}

//about us css
.about-us-section {
    padding: 200px 0 100px;
    position: relative;
    overflow: hidden;
    @include lg {
        padding: 50px 0;
    }
    .about-us-descritpion {
        @include laptop {
            margin-right: 20px !important;
        }
        @include xl {
            margin-right: 0 !important;
        }
    }
    &:after {
        content: "";
        background: url("../../images/home/about-us-lotus-pattern.png") no-repeat;
        background-size: 350px;
        position: absolute;
        width: 350px;
        height: 200px;
        top: 24%;
        right: -5%;
        z-index: -1;
    }
    .description-text {
        @include lg {
            text-align: center;
        }
    }
    .btn-medium {
        @include lg {
            margin: 30px auto 0 !important;
            display: block;
        }
    }
}

//service css
.services-section {
    position: relative;
    &:after {
        content: "";
        background: url("../../images/home/services-lotus-pattern.png") no-repeat;
        background-size: 250px;
        position: absolute;
        width: 250px;
        height: 220px;
        right: 0;
        bottom: -8%;
        z-index: -1;
    }
    .service-heading-panel {
        @include lg {
            flex-direction: column;
            .section-heading-panel {
                margin-bottom: 30px;
            }
        }
    }
    .service-panel {
        margin-bottom: 15px;
        position: relative;
        cursor: pointer;
        @include md {
            margin: 0 auto 15px !important;
        }
        &:hover {
            .service-details {
                height: auto;
                bottom: 0;
            }
        }
        .service-details {
            position: absolute;
            bottom: 30px;
            padding: 20px 30px 10px;
            background: $service-overlay-color;
            height: 60px;
            overflow: hidden;
            @include transition;
            @include md {
                position: relative;
                text-align: center;
                height: auto;
                bottom: auto;
                background: transparent;
                padding: 20px 0;
            }
            .service-title {
                font-size: $font25;
                @include md {
                    font-size: 20px;
                    color: $black-color;
                }
            }
            .service-description-panel {
                .service-desc-text {
                    line-height: $lheight24;
                    @include md {
                        color: $light-gray-color;
                        line-height: $lheight20;
                        font-size: $font14;
                    }
                }
                .link-text {
                    letter-spacing: 2px;
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        background: url("../../images/svg-icon/right-arrow-icon.svg") no-repeat;
                        background-position: center;
                        width: 10px;
                        height: 20px;
                        right: -15px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
    }
}

//client testimonial banner-css
.testimonial-section {
    position: relative;
    .section-heading-panel {
        margin-bottom: 80px;
    }
    &:after {
        content: "";
        background: url("../../images/home/testimonial-lotus-pattern.png") no-repeat;
        background-size: 200px;
        position: absolute;
        width: 200px;
        height: 230px;
        left: 0;
        bottom: 20%;
        z-index: -1;
    }
    .client-reviews-carousel {
        .owl-item.active {
            // &:first-child {
            //     .client-review-panel {
            //         @include box-shadow;
            //         &:before {
            //             opacity: 1;
            //         }
            //     }
            // }
        }
        .owl-nav {
            position: absolute;
            top: -20%;
            right: 0;
            @include lg {
                left: 0;
                margin: 30px auto 0;
                top: -30%;
            }
            .owl-prev {
                position: relative;
                background: transparent !important;
                opacity: 1;
                &:before {
                    content: "";
                    position: absolute;
                    background: url("../../images/svg-icon/testimonial-left-icon.svg") no-repeat;
                    left: 0;
                    top: 0;
                    width: 10px;
                    height: 20px;
                    background-size: 10px;
                }
            }
            .owl-next {
                position: relative;
                background: transparent !important;
                opacity: 1;
                &:before {
                    content: "";
                    position: absolute;
                    background: url("../../images/svg-icon/testimonial-right-icon.svg") no-repeat;
                    left: 0;
                    top: 0;
                    width: 10px;
                    height: 20px;
                    background-size: 10px;
                }
            }
        }
    }
    .client-review-panel {
        //max-width: 780px;
        //width: 100%;
        padding: 35px 90px 35px 45px;
        border-radius: 6px;
        background: $white-color url("../../images/svg-icon/text-quote-icon.svg") no-repeat;
        background-position: 95% 75%;
        border: 1px solid $light-gray-color;
        @include transition;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        @include lg {
            padding: 20px;
        }
        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 8px;
            background: $red-color;
            top: 0;
            left: 0;
            opacity: 0;
            @include transition;
        }
        &:hover {
            @include box-shadow;
            &:before {
                opacity: 1;
            }
        }
        .client-review-text {
            font-style: italic;
            line-height: $lheight24;
            @include lg {
                font-size: $font14;
            }
        }
        .client-img-panel {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            overflow: hidden;
            background: $light-green-color;
            .client-name-char {
                background: $light-green-color;
            }
        }
        .client-detail-panel {
            .client-name {
                color: $dark-gray-color;
                @include lg {
                    font-size: $font14;
                }
            }
            .client-role {
                color: $light-gray-color;
            }
        }
        .review-panel {
            height: 80px;
        }
    }
}

//newsletter css
.newsletter-section {
    background: $lightblue-back-color url("../../images/home/newsletter-lotus-pattern.png") no-repeat;
    background-position: -7% 50%;
    padding: 80px 0;
    position: relative;
    margin-bottom: 80px;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 50%;
        height: 4px;
        background: $light-green-color;
    }
    &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 50%;
        height: 4px;
        background: $light-green-color;
    }
    .newsletter-left-panel {
        padding-left: 120px;
        @include lg {
            padding-left: 50px;
        }
        @include md {
            padding-left: 0;
            text-align: center;
            margin-bottom: 30px;
        }
        .newsletter-title {
            color: $dark-back-color;
            @include md {
                font-size: $font25;
                line-height: $lheight30;
            }
        }
        .subscribe-text {
            letter-spacing: 12px;
            color: $dark-back-color;
            @include md {
                letter-spacing: 10px;
            }
        }
    }
    .newsletter-right-panel {
        .newsletter-input {
            // background-image: url("../../images/svg-icon/newsletter-email-icon.svg");
            // background-repeat: no-repeat;
            // background-position: 95%;
            height: 60px;
            border-color: $white-color;
            box-shadow: 0 16px 40px $input-box-shadow;
        }
        .envelop-icon {
            position: absolute;
            right: 50px;
            top: 20px;
            cursor: pointer;
        }
    }
}
