.custom-checkbox {
    label {
        color: $black-color !important;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        font-size: $font14;

        .checkbox-span {
            width: 22px;
            height: 22px;
            display: flex;
            justify-content: center;
            border: 1px solid $border-color;
            margin-right: 15px;
            border-radius: 3px;
            transition: all 0.3s;
            background: $white-color;
            position: relative;
            cursor: pointer;
        }
    }

    input {
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
        left: 0;

        &:checked + label > .checkbox-span {
            border: 1px solid $light-green-color;
        }

        &:checked + label > .checkbox-span::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 0px;
            background: $light-green-color url('../../images/svg-icon/checked-icon.svg') center no-repeat;
            background-size: contain;
        }

        &:checked + .checkbox-span {
            border: 1px solid $light-green-color;
        }

        &:checked + .checkbox-span::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 0px;
            background: $light-green-color url('../../images/svg-icon/checked-icon.svg') center no-repeat;
            background-size: contain;
        }
    }
}