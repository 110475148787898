.dropdown-block {
    .dropdown {
        .dropdown-toggle {
            text-align: start;
            background: $white-color;
            position: relative;
            font-size: 14px;
            line-height: 18px;
            color: #313131;
            border-radius: 6px;
            border: 1px solid #aeb5c5 !important;
            padding: 11px 20px;
            width: 100%;
            box-shadow: none !important;
            white-space: normal;
            @include sm{
                padding: 11px 5px;
            }
            &::after {
                content: "";
                position: absolute;
                border: solid $light-gray-color;
                border-width: 0 2px 2px 0;
                display: inline-block;
                transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                padding: 3px;
                right: 15px;
                @include transition;
                top: 40%;
            }
            &:focus{
                border: 1px solid $light-green-color !important;
            }
            &:active {
                background-color: $white-color;
                color: $black-color;
            }
        }
        .dropdown-disable {
            cursor: not-allowed;
        }
    }
    .dropdown-menu {
        border: 1px solid $light-gray-color;
        border-radius: 6px;
        margin-top: 5px;
        max-height: 195px;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;
        @include md {
            max-height: 170px;
        }
        // scrollbar-width: thin;
        // scrollbar-color: $light-green-color $light-gray-color;
        &::-webkit-scrollbar {
            width: 5px;
        }
        &::-webkit-scrollbar-track {
            box-shadow: none;
            border-radius: 0 10px 10px 0;
            background: $white-color;
            border-bottom: 1px solid $light-gray-color;
            border-top: 1px solid $light-gray-color;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: $light-green-color;
        }
        .dropdown-item {
            cursor: pointer;
            padding: 10px 20px;
            color: $black-color;
            white-space: normal;
            @include transition;
            &:last-child {
                margin-bottom: 0px;
            }
            &:hover {
                background-color: $light-green-color;
                color: $white-color;
            }
            &:active {
                color: $black-color;
            }
            .dropdown-item-panel-text {
                font-size: $font14;
                line-height: $lheight18;
                color: $black-color;
            }
        }
    }
}
