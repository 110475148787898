//font variables
/*theme one font variable*/
$font-stack: "NunitoSans-Regular", sans-serif;

//font size variables
$font50: 50px;
$font40: 40px;
$font30: 30px;
$font25: 25px;
$font20: 20px;
$font18: 18px;
$font16: 16px;
$font14: 14px;
$font12: 12px;
$font10: 10px;

//line height variables
$lheight54: 54px;
$lheight44: 44px;
$lheight34: 34px;
$lheight30: 30px;
$lheight28: 28px;
$lheight24: 24px;
$lheight22: 22px;
$lheight20: 20px;
$lheight18: 18px;
$lheight16: 16px;
$lheight14: 14px;
$lheight12: 12px;

//font weight variables
$fweight300: 300;
$fweight400: 400;
$fweight500: 500;
$fweight600: 600;
$fweight700: 700;
$fweight900: 900;

//media query breakpoints
$screen-desktop: 1920px;
$screen-laptop-max: 1680px;
$screen-laptop: 1440px;
$screen-xl-max: 1200px;
$screen-xl-min: 1024px;
$screen-lg-min: 992px;
$screen-lg-max: 991px;
$screen-md-max: 767px;
$screen-sm-max: 575px;
$screen-sm-min: 480px;
$screen-mobile-max: 375px;

//common -color variables
$white-color: #ffffff;
$dark-green-color: #183e22;
$black-color: #313131;
$dark-gray-color: #747982;
$gray-shade-color: #f2f2f2;
$light-green-color: #7ea831;
$light-gray-color: #aeb5c5;
$dark-back-color: #27313d;
$gray-border-color: #dbdddf;
$document-checklist-bg-color: #F5F6FF;
$border-color: #bbc1ce;
$yellow-color: #ffc20e;
$red-color: #ef4123;
$light-red-color: #ffe6e6;
$label-green-color: #008127;
$label-light-green-color: #d4ffdc;
$gray-border-color: #dbdddf;
$yellow-color: #ffc20e;
$label-green-color: #008127;
$label-light-green-color: #d4ffdc;
$label-pink-color: #ff2d85;
$label-light-pink-color: #ffeef5;
$label-yellow-color: #F2A60E;
$label-light-yellow-color:  #FFFBDF;
$label-blue-color: #0031d4;
$label-light-blue-color: #e8efff;
$label-brown-color: #804d00;
$label-light-red-color: #FFE8E8;
$label-red-color: #C40606;
$label-light-orange-color: #FFEDDC;
$label-orange-color: #FF7206;
$label-light-brown-color: #fff8de;
$label-purple-color: #8000ff;
$label-light-purple-color: #f9ecff;
$table-background-color: #f9fbff;
$pagination-background: #f4f5f8;
$green-gradiant-color: linear-gradient(#00210b, #315d3b);
$seperator-color: #4d6077;
$overlay-color: rgba(1, 1, 1, 0.3);
$footer-link-color: #a5a5a5;
$service-overlay-color: rgba(39, 49, 61, 0.67);
$dark-gray-color: #565656;
$dark-skyblue-shade: #eef5ff;
$lightblue-back-color: #f5f9ff;
$input-box-shadow: rgba(154, 170, 207, 0.2);
$tax-banner-back-color: #f7f9fa;
$resource-yellow-overlay: rgba(22, 33, 0, 0.7);
$resource-blue-overlay: rgba(39, 49, 61, 0.7);
$button-disabled-color: #e6e6e6;
$white-alpha-color: rgba(255, 255, 255, 0.4);
$banner-back-color: #E6EBEF;
$partial-paid-bg: #FFF4D6;
$partial-paid-text: #A77C00;
$paid-count-color: #205E06;
$unpaid-count-color: #9D200B;
$partially-count-color: #BF8F00;
$new-count-color: #27313D;
