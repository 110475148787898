.import-user-btn-panel {
    max-width: 125px;
    height: 40px;
    margin-left: auto;
    .file-input {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        bottom: auto;
        z-index: 99;
        cursor: pointer;
        text-indent: 130px;
    }
    .btn-import {
        position: relative;
        padding: 0 0 0 25px;
        width: 125px !important;
        height: 40px !important;
        &:before {
            content: "";
            position: absolute;
            background: url("../../../images/svg-icon/import.svg") no-repeat;
            width: 16px;
            height: 16px;
            left: 8px;
            top: 10px;
        }
    }
}
.contact-detail-panel {
    padding-left: 25px;
    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 14px;
        height: 12px;
    }
    &.email-panel {
        position: relative;
        &:before {
            background: url("../../../images/svg-icon/email-envelope-icon.svg") no-repeat;
        }
    }
    &.phone-panel {
        position: relative;
        &:before {
            background: url("../../../images/svg-icon/mobile-icon.svg") no-repeat;
        }
    }
}
.admin-table-card.admin-users-card {
    .table-header,
    .table-body {
        .table-data-row {
            .cell {
                &.cell-arrow {
                    display: none;
                    @include lg {
                        display: block;
                    }
                }
                &.cell-user-type{
                    width: 13%;
                    @include laptop-max{
                        width: 15%;
                    }
                    @include lg {
                        display: none;
                    }
                }
                &.cell-contact {
                    width: 22%;
                    padding-right: 10px;
                    @include lg {
                        width: 35%;
                    }
                    @include md {
                        width: 42%;
                    }
                    @include sm {
                        display: none;
                    }
                }
                &.cell-date {
                    width: 22%;
                    @include lg {
                        width: 25%;
                    }
                    @include md {
                        display: none;
                    }
                }
                &.cell-files-assigned {
                    width: 18%;
                    @include lg {
                        display: none;
                    }
                }
                &.cell-name {
                    .cell-body-data{
                        text-transform: capitalize;
                    }
                    width: 15%;
                    @include lg {
                        width: 25%;
                    }
                    @include md {
                        width: 35%;
                    }
                    @include sm {
                        width: 68%;
                    }
                    @include mobile {
                        width: 55%;
                    }
                }
            }
        }

        .table-card {
            left: 25px;
            .table-card-item {
                &.cell-files-assigned {
                    @include lg {
                        display: block;
                    }
                }
                &.cell-user-type {
                    @include lg {
                        display: block;
                    }
                }
                &.cell-date {
                    @include md {
                        display: block;
                    }
                }
                &.cell-contact {
                    @include sm {
                        display: block;
                    }
                }
            }
        }
    }
}
