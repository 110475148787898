.services-banner-section,
.banner-mobile-section.service-mobile-section {
    background: url("../../images/services/services-banner.png") no-repeat;
    background-size: cover;
}
.service-section {
    .service-description {
        line-height: $lheight22;
        @include xl {
            font-size: $font14;
            line-height: $lheight20;
        }
    }
    .service-main-wrapper {
        @include xl-lg {
            display: flex;
        }
        @include lg{
            margin-bottom: 70px;
        }
        &.reverse-column{
            @include xl-lg {
                flex-direction: row-reverse;
                margin-top: 70px;
            }
            .service-img-wrapper {
                &.top-wrapper:after {
                    @include xl-lg {
                        right: 0;
                        left: auto;
                    }
                }
            }
        }
        .pl-70{
            @include xl-lg{
                padding-left: 20px;
            }
            @include lg{
                padding-left: 0;
            }
        }
        .service-img-wrapper{
            @include xl-lg {
                flex: 1;
            }
        }
    }
    .service-img-wrapper {
        position: relative;
        padding-right: 70px;
        display: flex;
        align-items: center;
        
        &.black-wrapper {
            &:after {
                background: $dark-back-color;
            }
        }
        &.top-wrapper {
            padding: 50px 50px 0;
            @include xl-lg {
                padding: 0 0 0 20px;
            }
            @include lg{
                padding: 20px 0 0;
            }
            &:after {
                top: 0;
                height: 150px;
                width: 100%;
                @include xl-lg {
                    height: 100%;
                    width: 80px;
                    left: 0;
                }
                @include lg{
                    height: 80px;
                }
            }
        }
        @include xl {
            padding-right: 20px;
        }
        @include lg {
            padding-right: 0;
            padding-top: 20px;
            max-width: 80%;
            margin: 0 auto 20px;
            height: auto !important;
        }
        @include sm {
            max-width: 100%;
        }
        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            width: 150px;
            background: $light-green-color;
            @include xl {
                width: 70px;
            }
            @include lg {
                top: 0;
                height: 70px;
                width: 100%;
            }
        }
        img {
            position: relative;
            z-index: 9;
        }
    }
    .service-list {
        .service-list-item {
            padding-left: 30px;
            position: relative;
            margin-bottom: 15px;
            @include xl {
                margin-bottom: 5px;
            }
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 6px;
                margin: auto;
                width: 8px;
                height: 8px;
                background: $light-green-color;
                border-radius: 50%;
            }
            span {
                @include xl {
                    font-size: $font14;
                }
            }
            .return-for-list {
                counter-reset: my-sec-counter;
                margin-top: 15px;
                .return-for-list-item {
                    position: relative;
                    padding-left: 30px;
                    margin-right: 15px;
                    margin-bottom: 10px;
                    @include sm {
                        width: 100%;
                    }
                    &:before {
                        content: counter(my-sec-counter);
                        counter-increment: my-sec-counter;
                        position: absolute;
                        left: 0;
                        top: 2px;
                        background: $dark-back-color;
                        border-radius: 50%;
                        width: 18px;
                        height: 18px;
                        color: $white-color;
                        text-align: center;
                        font-size: $font12;
                        line-height: $lheight18;
                    }
                }
            }
        }
    }
    .services-block {
        @include xl {
            margin-top: 15px !important;
        }
        .large-font {
            @include xl {
                font-size: $font16;
            }
        }
    }
}
.account-service-section {
    padding: 150px 0 50px;
    position: relative;
    overflow: hidden;
    @include lg {
        padding: 50px 0;
    }
    &:after {
        content: "";
        background: url("../../images/home/about-us-lotus-pattern.png") no-repeat;
        background-size: 400px;
        position: absolute;
        width: 400px;
        height: 250px;
        bottom: 20%;
        right: -8%;
        z-index: -1;
    }
}
.tax-service-section {
    background: $lightblue-back-color;
}
.other-service-section {
    position: relative;
    overflow: hidden;
    &:after {
        content: "";
        background: url("../../images/services/lotus-pattern-img.png") no-repeat;
        background-size: 400px;
        position: absolute;
        width: 400px;
        height: 250px;
        bottom: 10%;
        right: -8%;
        z-index: -1;
    }
}
