.banner-mobile-section.inner-mobile-section {
    z-index: 9;
    .banner-description {        
        max-width: initial;
        position: relative;
        bottom: auto;
        right: auto;
    }
    @include lg {
        display: flex !important;
        align-items: center;
        justify-content: flex-end;
        height: 300px !important;
        padding: 80px 70px 80px 100px !important;
    }
    @include sm {
        padding: 80px 15px 80px 70px !important;
        height: 250px !important;
    }
}
.inner-banner-section,
.banner-mobile-section.about-mobile-section {
    background: url("../../images/about-us/about-us-banner.png") no-repeat;
    background-color: transparent !important;
    background-position: center right !important;
    background-size: cover;
    padding: 150px 0 30px;
    height: 500px;
    @include laptop {
        padding: 90px 0 30px;
        height: auto;
    }
    @include lg {
        padding: 50px 0 30px;
        background-position: 0 0 !important;
    }
    @include md{
        overflow: initial;
    }
    @include sm {
        padding: 30px 0;
    }
    .section-heading-panel {
        position: relative;
        z-index: 9;
        .banner-heading-text {
            @include lg {
                font-size: $font40;
            }
            @include sm {
                font-size: $font30;
                line-height: $lheight28;
            }
        }
        .banner-subheading-text {
            max-width: 50%;
            margin-bottom: 30px;
            line-height: $lheight24;
            @include laptop {
                max-width: 80%;
            }
            @include sm {
                line-height: $lheight18;
            }
        }
        @include lg {
            text-align: left !important;
        }
    }
    .slant-img-panel {
        left: 0;
        animation: none;
        bottom: -30px;
        top: auto;
        @include desktop {
            left: -8%;
        }
        @include laptop {
            left: -15%;
        }
        @include xl {
            left: -25%;
        }
        @include lg {
            left: 0;
        }
        @include sm {
            height: 450px;
        }
        img {
            @include lg {
                width: 100%;
            }
        }
    }
    .lotus-pattern-panel {
        left: -110px;
        width: 35%;
        top: 20px;
        @include laptop {
            width: 40%;
            top: 0;
        }
        @include xl {
            width: 50%;
        }
        @include lg {
            left: 0;
        }
    }
    .banner-description {
        padding-left: 100px;
        animation: none;
        opacity: 1;
        max-width: initial;
        position: relative;
        bottom: auto;
        right: auto;
        @include sm {
            padding-left: 32px;
        }
    }
}
.about-us-section {
    .about-img-wrapper {
        @include lg {
            max-width: 50%;
            margin: 0 auto;
        }
        @include sm {
            max-width: 60%;
        }
    }
    .about-main-panel {
        margin-top: 150px;
        @include lg {
            margin-top: 50px;
        }
    }
    .about-panel {
        background: $lightblue-back-color;
        padding: 40px 20px 40px 110px;
        position: relative;
        border-radius: 7px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;
        @include laptop {
            padding: 40px 10px 40px 80px;
        }
        @include lg {
            height: auto;
        }
        .about-title {
            font-size: $font25;
            @include laptop {
                font-size: $font20;
            }
        }
        .about-details {
            font-size: $font18;
            line-height: $lheight22;
            @include laptop {
                font-size: $font16;
            }
        }
        &:before {
            content: "";
            position: absolute;
            left: 20px;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 66px;
            height: 78px;
            @include laptop {
                background-size: 50px !important;
                height: 50px;
                width: 50px;
            }
        }
        &.expert-panel {
            &:before {
                background: url("../../images/svg-icon/expert-icon.svg") no-repeat;
            }
        }
        &.profit-panel {
            &:before {
                background: url("../../images/svg-icon/profits-icon.svg") no-repeat;
            }
        }
        &.creativity-panel {
            &:before {
                background: url("../../images/svg-icon/creativity-icon.svg") no-repeat;
            }
        }
    }
}
.counter-section {
    background: url("../../images/about-us/counter-back-img.png") no-repeat;
    background-size: cover;
    padding: 60px 0;
    @include lg {
        padding: 60px 0 0;
    }
    .counter-panel {
        @include lg {
            margin-bottom: 70px;
        }
        .counter-count {
            font-size: $font40;
            line-height: $lheight44;
            @include lg {
                font-size: $font30;
                line-height: $lheight30;
            }
        }
        .counter-title {
            font-size: $font25;
            @include lg {
                font-size: $font20;
            }
            @include lg {
                font-size: $font18;
            }
        }
    }
}
.expert-section {
    background: url("../../images/about-us/expert-lotus-pattern.png") no-repeat;
    background-position: top left;
    padding: 150px 0;
    @include lg {
        padding: 50px 0;
    }
    .expert-img-wrapper {
        @include lg {
            max-width: 50%;
            margin: 0 auto;
        }
        @include sm {
            max-width: 80%;
        }
    }
    .expert-details-panel {
        background: $light-green-color;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 30px;
        @include laptop {
            padding: 20px;
        }
        @include lg {
            max-width: 50%;
            margin: 0 auto;
            height: 250px;
            text-align: center;
        }
        @include sm {
            max-width: 80%;
        }
        &:before {
            content: "";
            position: absolute;
            background: url("../../images/svg-icon/triangle-arrow.svg") no-repeat;
            left: -15px;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 15px;
            height: 27px;
            @include lg {
                left: 0;
                right: 0;
                bottom: auto;
                top: -20px;
                transform: rotate(90deg);
            }
        }
        .expert-goal-text {
            @include laptop {
                font-size: $font14;
            }
        }
        .expert-name {
            font-size: $font25;
            // @include laptop{
            //     font-size: $font20;
            //     line-height: $lheight24;
            // }
        }
        .expert-designation {
            font-size: $font20;
            @include laptop {
                font-size: $font18;
            }
        }
        .expert-social-list {
            opacity: 0;
            @include lg {
                justify-content: center;
            }
        }
    }
    .expert-description-panel {
        border: 1px solid $light-green-color;
        border-radius: 13px;
        padding: 30px 20px;
        margin-left: 15px;
        @include lg {
            margin: 15px auto 0;
        }
        p {
            line-height: $lheight24;
            @include laptop {
                font-size: $font14;
                line-height: $lheight20;
            }
        }
    }
}
.choose-us-section {
    background: $lightblue-back-color;
    margin-bottom: 80px;
    .section-left-panel {
        padding: 0 100px;
        @include laptop-max {
            padding: 0 50px;
        }
        @include laptop {
            padding: 0 0 0 50px;
        }
        @include xl {
            padding: 0;
        }
    }
    .feature-list {
        @include lg {
            text-align: center;
            max-width: 80%;
            margin: 35px auto 0;
        }
        @include mobile {
            max-width: 100%;
        }
        .feature-list-item {
            margin-bottom: 30px;
            .feature-title {
                font-size: $font20;
                @include xl {
                    font-size: $font18;
                }
            }
            .feature-description {
                font-size: $font18;
                line-height: $lheight24;
                @include xl {
                    font-size: $font16;
                }
            }
        }
    }
    .consultation-form-panel {
        background: $white-color;
        padding: 35px 55px;
        box-shadow: 0 16px 40px rgba(154, 170, 207, 0.2);
        max-width: 560px;
        border-radius: 12px;
        position: relative;
        @include lg {
            margin: 0 auto;
        }
        @include sm {
            padding: 20px;
        }
        .form-title {
            text-transform: uppercase;
        }
        .consultation-form {
            margin-top: 30px;
            .phonenumber-panel{
                .number-prefix{
                    left: 0;
                    top: 38px;
                }
                .input-block{
                    .form-input {
                        padding-left: 40px;
                    }
                }
            }
            .form-group {
                margin-bottom: 40px;
                .form-input {
                    border: 0;
                    border-bottom: 1px solid $light-gray-color;
                    border-radius: 0;
                    padding-left: 0;
                }
            }
            .btn-small {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                bottom: -20px;
            }
        }
    }
}
