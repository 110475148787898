.MuiDialog-container {
    .MuiPaper-root {
        border-radius: 12px;
        width: 500px;
        box-shadow: 0px 16px 40px rgba(49, 49, 49, 0.19);
        .cancel-btn {
            cursor: pointer;
        }
        .MuiDialogContent-root {
            border: none;
            padding: 0px 24px;
            &::-webkit-scrollbar {
                width: 5px;
            }
            &::-webkit-scrollbar-track {
                box-shadow: none;
                border-radius: 10px;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background: $light-green-color;
            }
            @include sm {
                padding: 0 15px;
            }
            .title-panel {
                .title-panel-text {
                    font-size: $font25;
                    line-height: $lheight30;
                    @include sm {
                        font-size: $font20;
                        line-height: $lheight24;
                    }
                    @include mobile {
                        font-size: $font16;
                        line-height: $lheight20;
                    }
                }
                @include sm {
                    text-align: center;
                }
            }
            .button-panel {
                margin-top: 80px;
                display: flex;
                .no-btn {
                    margin-right: 20px;
                }
                .btn-small {
                    @include sm {
                        margin-top: 15px;
                    }
                }
                @include lg {
                    margin-top: 50px;
                }
                @include sm {
                    flex-wrap: wrap;
                    justify-content: center;
                }
                @include mobile {
                    margin-top: 20px;
                    //justify-content: center;
                }
            }
            .right-image-block {
                display: flex;
                img {
                    @include sm {
                        margin: 20px auto 0 !important;
                    }
                }
            }
            .input-panel {
                .form-input {
                    border-radius: 30px;
                }
            }
        }
    }
}
