.document-popup {
    .MuiDialog-container .MuiPaper-root {
        max-width: 800px;
        width: 100%;
        .MuiDialogContent-root {
            padding: 25px;
        }
    }
    .pg-viewer-wrapper {
        overflow-y: auto !important;
        height: 575px;
    }
}
.not-supported-panel{
    height: 300px;
    flex-direction: column;
}