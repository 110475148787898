.header-section {
    padding: 20px 0;
    .toggle-menu {
        display: none;
        cursor: pointer;
        margin-right: 10px;
        @include lg {
            display: block;
            z-index: 99;
        }
    }
    .login-web-btn {
        display: block;
        @include lg {
            display: none;
        }
    }
    .login-mob-btn {
        display: none;
        @include lg {
            display: block;
        }
    }
    &.form-header-section {
        padding: 15px 0px;
        border-bottom: 1px solid $light-gray-color;
        .logo-panel {
            z-index: 9;
            @include sm {
                width: 35%;
            }
            img {
                width: 100%;
            }
        }
        .form-nav-panel {
            position: relative;
            align-items: center;
            @include lg {
                flex-direction: column;
            }
            @include md {
                align-items: flex-end;
            }
        }
        .header-btn {
            margin-left: 15px;
            // @include lg {
            //     margin: 20px 0;
            //     margin-left: 0 !important;
            // }
            .btn-small {
                @include sm {
                    width: 76px;
                }
            }
        }
        .auth-redirection-panel {
            @include md {
                flex-direction: column;
                justify-content: flex-end !important;
                align-items: flex-end !important;
            }
        }
        .header-buttons-panel {
            display: flex;
            @include md {
                margin-top: 10px;
            }
        }
    }
    .navbar-panel {
        position: relative;
        .logo-panel {
            position: relative;
            z-index: 99;
        }
        .nav-panel {
            z-index: 99;
            @include lg {
                position: absolute;
                top: 0;
                right: 0;
                background: $dark-back-color;
                max-width: 300px;
                width: 100%;
                border-radius: 8px;
                display: block !important;
                opacity: 0;
                @include transition;
                transform: translateY(100px);
                z-index: -1;
                &.open-menu {
                    opacity: 1;
                    transform: translateY(70px);
                    z-index: 999;
                }
            }
            // &.admin-nav-panel{
            //     .nav-link {
            //         padding: 0 22px;
            //     }
            // }
            .nav-link {
                background: transparent;
                border: 0;
                padding: 0 22px;
                color: $black-color;
                @include transition;
                cursor: pointer;
                font-family: "NunitoSans-Bold", sans-serif;
                @include laptop {
                    padding: 0 18px;
                }
                @include xl {
                    padding: 0 12px;
                    font-size: $font14;
                }
                @include lg {
                    padding: 20px 10px;
                    border-bottom: 1px solid $seperator-color !important;
                    width: 100%;
                    color: $light-gray-color;
                    text-align: center;

                    &:last-child {
                        border-bottom: 0 !important;
                    }
                }
                &.active-link {
                    color: $light-green-color;
                    @include md {
                        color: $white-color;
                    }
                }
                &:hover {
                    color: $light-green-color;
                    @include lg {
                        color: $white-color;
                    }
                }
                &:focus {
                    outline: none;
                }
            }
        }
    }
}
.profile-dropdown {
    position: relative;
    z-index: 99;
    .dropdown-block .dropdown {
        .dropdown-toggle {
            padding: 0;
            width: 30px;
            height: 30px;
            border: 0 !important;
            &:after {
                content: "";
                position: absolute;
                background: url("../../images/svg-icon/profile-user.svg") no-repeat;
                width: 30px;
                height: 30px;
                left: 0;
                top: 0;
                border: 0;
                transform: rotate(0);
            }
            &:focus {
                border: 0 !important;
            }
        }
        .dropdown-menu {
            right: 0 !important;
            left: auto !important;
            transform: translate(0, 35px) !important;
            padding: 0;
            .dropdown-item {
                padding: 0;
                &.user-dropdown-item:hover{
                    background: transparent;
                }
                .user-profile-div{
                    padding: 10px 15px;
                    .profile-name{
                        text-transform: capitalize;
                        position: relative;
                        padding-left: 25px;
                        &:before{
                            content: '';
                            background: url('../../images/svg-icon/user-profile-icon.svg') no-repeat;
                            width: 15px;
                            height: 15px;
                            left: 0;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            position: absolute;
                        }
                    }
                }
                &:hover {
                    a {
                        color: $white-color;
                    }
                }
                a {
                    color: $black-color;
                    padding: 10px 15px;
                    display: block;
                }
            }
        }
    }
}
