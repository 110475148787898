.form-group {
    border: none;
    padding: 0px;
    margin: 0px;
    .form-input {
        font-size: $font14;
        line-height: $lheight18;
        color: $black-color;
        border-radius: 6px;
        border: 1px solid $light-gray-color;
        height: 40px;
        padding: 10px 20px;
        width: 100%;
        &.search-dropdown-input {
            width: 85%;
            margin-left: 20px;
        }
        &:focus {
            outline: none;
            border: 1px solid $light-green-color;
        }
        &::placeholder {
            font-size: $font14;
            line-height: $lheight18;
            font-family: "NunitoSans-Italic", sans-serif;
            color: $light-gray-color;
        }
    }
    .badge-danger {
        white-space: pre-wrap;
        text-align: left;
        margin-top: 5px;
        font-family: $font-stack;
        font-weight: normal;
    }
}
.MuiPickersDatePickerToolbar-penIcon {
    display: none !important;
}
.MuiDialog-container .MuiPaper-root .MuiDialogContent-root.MuiPickersModalDialog-dialog {
    padding: 0px !important;
    .MuiPickersDatePickerToolbar-root {
        background: $light-green-color;
        .MuiTypography-h4{
            font-size: $font25;
        }
    }
    .MuiPickersDay-root.Mui-selected {
        background: $light-green-color;
        outline: none;
    }
}
.MuiButton-textPrimary {
    background: $light-green-color !important;
    border-radius: 8px !important;
    &:first-child{
        display: none;
    }
    .MuiButton-label {
        color: $white-color;
    }
}
