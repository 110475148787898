.animated-box{
    animation-duration: 1.3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: placeHolderShimmer;
    background: linear-gradient(to right, $lightblue-back-color 8%, $dark-skyblue-shade 18%, $lightblue-back-color 33%);
    background-size: 1000px 104px;
    width: 92px;
    height: 28px;
    border-radius: 12px;
    &.large-box{
        height: 165px;
    }    
}
@keyframes placeHolderShimmer {
	0%{ background-position: -468px 0; }
    100%{ background-position: 468px 0; }
}
.error-wrapper{   
    margin: 180px 0px 150px 0px;
    // @include sm{
    //     margin: 30px 0px 50px 0px;
    // }
    .error-section{
        position: relative;
        .animated-box{
            width: 100%;
            height: 60px;
            @include md{
                height: 40px;
            }
            &.small-box{
                width: 446px;            
                @include md{
                    width: 100%;
                }
            }
            &.large-box{
                width: 100%;
            }
        }
        .under-construction-panel{
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            margin: 0 auto;
            text-align: center;
            @include sm{
                top: 40%;
            }
            .btn-small{
                @include sm{
                    width: 120px;
                }
            }
            .text-block{
                margin-top: 30px;
                .error-message{
                    font-size: $font50;
                    line-height: $lheight54;
                    color: $light-green-color;
                    @include sm{
                        font-size: $font30;
                        line-height: $lheight34;
                    }
                }
                .suberror-messsage{
                    font-size: $font14;
                    line-height: $lheight18;
                    color: $black-color;
                    @include sm{
                        font-size: $font12;
                        line-height: $lheight16;
                    }
                }     
            }
            .image-section{
                z-index: 9;
                position: relative;
                .image-block{
                    z-index: -1;
                    position: absolute;
                    left: 0;
                    top: -80%;
                    right: 0;                  
                    img {
                        -webkit-animation: mover 2s infinite  alternate;
                        animation: mover 2s infinite  alternate;     
                        @include sm{
                            width: 200px;
                        }                  
                    }
                    @-webkit-keyframes mover {
                        0% { transform: translateY(0px); }
                        100% { transform: translateY(40px); }
                    }
                    @keyframes mover {
                        0% { transform: translateY(0px); }
                        100% { transform: translateY(40px); }
                    }
                }
                .image-block1{
                    img{
                        @include sm{
                            width: 250px;
                        }  
                    }
                }
               
            }       
        }
    }
}