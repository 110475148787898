.payment-gateway-page {
    margin: 70px 0px;
    .payment-gateway-header {
        border-bottom: 1px solid $light-gray-color;
        padding: 0px 0px 10px 0px;
    }
    .payment-main-panel {
        max-width: 85%;
        margin: 150px auto;
        @include xl {
            max-width: 100%;
        }
        @include lg {
            display: block !important;
        }
    }
    .package-detail-block {
        flex: 1;
        margin: 0 10px;
        @include lg {
            margin: 0 10px 50px;
        }
        .detail-container {
            @include box-shadow;
            border-radius: 8px;
            padding: 30px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            @include md {
                padding: 15px;
            }
            .features-block {
                margin-top: 30px;
                @include md {
                    margin-top: 15px;
                }
            }
            .right-panel {
                margin-left: 15px;
                .bullet-desc {
                    .bullet-desc-text {
                        font-size: $font18;
                        line-height: $lheight22;
                        color: #aeb5c5;
                        @include md {
                            font-size: $font16;
                            line-height: $lheight20;
                        }
                    }
                }
                .pricing-panel {
                    margin-top: 10px;
                    text-align: end;
                    @include sm {
                        text-align: start;
                    }
                    .pricing-panel-text {
                        font-size: $font30;
                        line-height: $lheight34;
                        color: $black-color;
                        @include md {
                            font-size: $font18;
                            line-height: $lheight22;
                        }
                    }
                }
            }
        }
    }
}
.StripeElement {
    margin: 16px 0 16px;
    background-color: $white-color;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    box-shadow: 0 1px 3px 0 $input-box-shadow;
    transition: box-shadow 150ms ease;
    width: 100%;
}
