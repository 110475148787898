.session-selection-popup{
    .MuiDialog-container{
        .MuiPaper-root{
            border-radius: 12px;       
            width: 500px;
            box-shadow: 0px 16px 40px rgba(49,49,49,0.19);
            .cancel-btn{
                cursor: pointer;
            }
            .MuiDialogContent-root {
                border: none;  
                padding: 0px 30px 30px 30px; 
                @include sm{
                    padding: 0px 20px 20px 20px;
                }    
                .select-block{
                    margin-top: 30px;
                    .selection-container{                       
                        width: 200px;
                        height: 200px; 
                        border-radius: 9px;
                        cursor: pointer;
                        @include transition;                          
                        @include sm{
                            width: 130px;
                            height: 150px; 
                        }    
                        @include mobile{
                            width: 100px;
                            height: 130px; 
                        }            
                        &.incomplete{
                            background:$lightblue-back-color url("../../../images/popup/incomplet-application-icon.png") no-repeat center;    
                        }
                        &.new{
                            background:$lightblue-back-color url("../../../images/popup/new-application-icon.png") no-repeat center;
                        }
                        &:hover,&.active{
                            border: 1px solid $light-green-color;
                            box-shadow: 0px 16px 40px rgba(154,170,207,0.20);
                            &.incomplete{
                                background:$white-color url("../../../images/popup/incomplet-application-hover.png") no-repeat center;
                            }
                            &.new{
                                background:$white-color url("../../../images/popup/new-application-hover.png") no-repeat center;
                            }
                        }
                    }
                }           
            }
        }
    }
}