.heading-panel {
    margin-bottom: 50px;
    @include lg{
        margin-bottom: 30px;
    }
    .taxPage-heading-text {
        margin-bottom: 20px;
        @include xl {
            font-size: $font30;
            line-height: $lheight34;
        }
        @include sm {
            font-size: $font25;
            line-height: $lheight28;
        }
    }
    .taxPage-subheading-text {
        max-width: 38%;
        margin: 0 auto;
        font-style: italic;
        @include xl {
            max-width: 60%;
        }
        @include sm{
            max-width: 100%;
            font-size: $font14;
        }
    }
}
.bliss-about-section{
    margin: 100px 0px 50px 0px;
    @include xl{
        margin-top: 70px 0px 50px 0px;
    }   
    @include md{
        margin: 50px 0px 50px 0px;
    }
    .about-bliss-content-block{
        margin-right: 200px;
        @include laptop{
            margin-right: 100px;
        }
        @include lg{
            margin-right: 20px;
        }
        @include md{
            text-align: center;
            margin-bottom: 50px;
        }
        .about-heading-panel{
            .about-heading-panel-text{
                font-size: $font40;
                line-height: $lheight44;
                font-family: "NunitoSans-Bold",sans-serif;
                @include xl{
                    font-size: $font30;
                    line-height: $lheight34;
                }
                @include md{
                    font-size: $font18;
                    line-height: $lheight22;
                }
            }
        }
        .about-desc-panel{
            margin-top: 20px;
            .about-desc-panel-text{
                font-size: $font18;
                line-height: $lheight22;
                font-family: "NunitoSans-Bold",sans-serif;
                color: $light-gray-color;       
                @include xl{
                    font-size: $font16;
                    line-height: $lheight20;
                }     
                @include md{
                    font-size: $font14;
                    line-height: $lheight18;
                }    
            }
        }
    }
}
.tax-testimonial-section {
    .owl-stage-outer {
        padding: 10px 0;
    }
    .client-review-panel {
        position: relative;
        background-position: 75% 75% !important;
        background: $white-color url("../../images/svg-icon/review-gray-quote-icon.svg") no-repeat;
        border: 0;
        padding: 35px 45px;
        //max-width: 520px;
        @include box-shadow;
        // @include md{
        //     max-width: 100%;
        // }
        &:before {
            content: none;
        }
        &:after {
            content: "";
            position: absolute;
            background: url("../../images/svg-icon/review-dotted-pattern.svg") no-repeat;
            right: 20px;
            bottom: 20px;
            width: 40px;
            height: 40px;
        }
        .review-panel{
            height: 80px;
        }
    }
}
.file-tax-section {
    background: url("../../images/tax/tax-file-img.png") no-repeat;
    text-align: center;
    background-size: cover;
    margin-bottom: 60px;
}
