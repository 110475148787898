
.email-popup{
    .MuiDialog-container{
        .MuiPaper-root{
            border-radius: 12px;       
            width: 500px;
            box-shadow: 0px 16px 40px rgba(49,49,49,0.19);
            .cancel-btn{
                cursor: pointer;
            }
            .MuiDialogContent-root {
                border: none;  
                padding: 0px 30px 30px 30px; 
                @include sm{
                    padding: 0px 10px 10px 10px;
                }            
                .input-panel{
                    .form-input{
                        border-radius: 30px;
                    }
                }
                .button-panel{
                    display: flex;     
                    margin-top: 30px;
                    justify-content: center;         
                    // @include sm{
                    //     display: block;
                    // }
                    .submit-btn{
                        margin-left: 20px;
                        // @include sm{
                        //     margin-left: 0px;
                        //     margin-top: 15px;
                        // }
                    }
                }
                .otp-input-panel{
                    margin-top: 15px;
                    .otp-input{
                        max-width: 60px;
                        width: 2em !important;
                        height: 60px;
                        border-radius: 14px;
                        padding: 0 15px;
                        margin: 0 10px;
                        text-align: center;
                        font-size: $font25;
                        @include lg{
                            padding: 0 15px;
                        }
                        @include sm{
                            width: 30px;
                            height: 30px;
                            padding: 0 8px;
                            font-size: $font18;
                            margin: 0 5px;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
}