.save-contact-panel {
    padding: 10px 20px;
}
.btn-xsmall {
    min-width: 60px;
    width: auto;
    height: 27px;
    font-size: $font14;
    line-height: $lheight14;
    border-radius: 4px;
}

.w-86 {
    width: 86px;
}
