.footer-section {
    background: $dark-back-color;
    padding: 40px 0;
    .footer-upper-panel {
        border-bottom: 1px solid $seperator-color;
        padding-bottom: 30px;
        margin-bottom: 30px;
        .about-us-panel {
            padding-right: 100px;
            @include xl {
                padding-right: 30px;
            }
            @include lg {
                padding: 0 0 050px;
            }
            .footer-title {
                font-size: 16px;
            }
            .about-description {
                color: $white-color;
            }
            .small-font {
                @include mobile {
                    font-size: 10px;
                    line-height: 14px;
                }
            }
            .contact-panel {
                .contact-info {
                    position: relative;
                    padding-left: 40px;
                    margin-top: 25px;
                    white-space: nowrap;
                    a {
                        &:hover {
                            color: $white-color;
                        }
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        width: 20px;
                        height: 20px;
                        left: 0;
                        background-position: center !important;
                    }
                    &.address-info {
                        &:before {
                            background: url("../../images/svg-icon/map-marker-icon.svg") no-repeat;
                        }
                    }
                    &.phone-info {
                        &:before {
                            background: url("../../images/svg-icon/phone-icon.svg") no-repeat;
                        }
                    }
                    &.toll-free {
                        &:before {
                            background: url("../../images/svg-icon/toll-free-footer-icon.svg") no-repeat;
                        }
                        &.another-number {
                            padding-left: 30px;
                            margin-left: 30px;
                            position: relative;
                            &:after {
                                content: "";
                                position: absolute;
                                left: -15px;
                                top: 0;
                                width: 1px;
                                height: 20px;
                                background: $seperator-color;
                            }
                        }
                    }
                    &.fax-info {
                        &:before {
                            background: url("../../images/svg-icon/fax-footer-icon.svg") no-repeat;
                        }
                    }
                    &.email-info {
                        &:before {
                            background: url("../../images/svg-icon/email-icon.svg") no-repeat;
                        }
                    }
                }
            }
        }
        .footer-links-panel {
            @include md {
                padding: 0 0 30px;
            }
            .footer-links-list {
                .footer-link {
                    position: relative;
                    color: $footer-link-color;
                    padding-left: 25px;
                    margin-bottom: 15px;
                    display: block;
                    @include xl {
                        font-size: $font12;
                        padding-left: 20px;
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        background: url("../../images/svg-icon/footer-right-arrow.svg") no-repeat;
                        background-position: center;
                        width: 15px;
                        height: 12px;
                        left: 0;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
        .social-icons-panel {
            .social-links-list {
                .social-link {
                    margin-right: 25px;
                    @include xl {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    .footer-lower-panel {
        .policy-links-list {
            .policy-link {
                color: $white-color;
                @include md {
                    &.mr-60 {
                        margin-right: 20px !important;
                    }
                }
            }
        }
    }
    .policy-pane {
        cursor: pointer;
        position: fixed;
        background: $light-green-color;
        z-index: 9;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 5px 10px;
        a{
            &:hover{
                color: $white-color;
            }
        }
    }
}
