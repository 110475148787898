.assign-popup{
    .MuiPaper-root{
        height: 330px;
        .MuiDialogTitle-root{
            .upload-popup-heading{
                text-align: center;
                @include sm{
                    text-align: left;
                }
            }
        }
        .MuiDialogContent-root{
            overflow: hidden;
            .dropdown-block .dropdown-menu{
                height: 154px;
            }
            .form-group .form-input.search-dropdown-input{
                width: 93%;
                margin: 15px;
                @include sm{
                    width: 85%;
                }
            }
        }
    }
}