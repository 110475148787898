@font-face {
    font-family: 'NunitoSans-Regular';
    src: url('../../fonts/nunito-sans/NunitoSans-Regular.ttf');
}
@font-face {
    font-family: 'NunitoSans-SemiBold';
    src: url('../../fonts/nunito-sans/NunitoSans-SemiBold.ttf');
}
@font-face {
    font-family: 'NunitoSans-Bold';
    src: url('../../fonts/nunito-sans/NunitoSans-Bold.ttf');
}
@font-face {
    font-family: 'NunitoSans-Black';
    src: url('../../fonts/nunito-sans/NunitoSans-Black.ttf');
}
@font-face {
    font-family: 'NunitoSans-ExtraBold';
    src: url('../../fonts/nunito-sans/NunitoSans-ExtraBold.ttf');
}
@font-face {
    font-family: 'NunitoSans-Italic';
    src: url('../../fonts/nunito-sans/NunitoSans-Italic.ttf');
}
@font-face {
    font-family: 'NunitoSans-Black';
    src: url('../../fonts/nunito-sans/NunitoSans-Black.ttf');
}

h1 {
    font-size: $font50;
    line-height: $lheight54;
}

h2 {
    font-size: $font40;
    line-height: $lheight44;
}

h3 {
    font-size: $font25;
    line-height: $lheight30;
}

h5 {
    font-size: $font20;
    line-height: $lheight24;
}

p {
    font-size: $font16;
    line-height: $lheight20;
}

a:hover {
    text-decoration: none;
}
.meta-info {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    padding-right: 10px;
}
span,
a {
    font-size: $font14;
}

//font-scss
.small-font{
    font-size: $font14;
    line-height: $lheight18;
    @include sm{
        font-size: $font12;
        line-height: $lheight14;
    }
}
.default-font{
    font-size: $font16;
    line-height: $lheight20;
    @include md{
        font-size: $font14;
        line-height: $lheight18;
    }
}
.large-font{
    font-size: $font18;
    line-height: $lheight22;
    @include md{
        font-size: $font16;
        line-height: $lheight20;
    }
    @include mobile{
        font-size: $font14;
        line-height: $lheight18;
    }
}
.dark-green-text{
    color: $dark-green-color;
}
.light-green-text{
    color: $light-green-color;
}
.light-gray-text{
    color: $light-gray-color;
}
.black-text{
    color: $black-color;
}
.white-text{
    color: $white-color;
}
.link-text{
    cursor: pointer;
    &:hover{
        color: $light-green-color;
    }
}

//font-weight-scss


.font-600{
    font-family: "NunitoSans-SemiBold",sans-serif;
}
.font-700{
    font-family: "NunitoSans-Bold",sans-serif;
}
.font-800{
    font-family: 'NunitoSans-ExtraBold', sans-serif;
}
.font-900{
    font-family: 'NunitoSans-Black',sans-serif;
}
.NunitoSans-Italic{
    font-family: "NunitoSans-Italic",sans-serif;
}
.NunitoSans-Black{
    font-family: "NunitoSans-Black",sans-serif;
}