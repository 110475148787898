.user-panel-page {
    margin: 70px 0px;

    //notification panel

    .user-notification-panel {
        margin: 30px 0px;
        display: flex;
        .btn-notification {
            margin-right: 20px;
            height: 40px;
            width: 242px;
        }
        &.btn-status {
        }
    }

    .information-message {
        background: #ffeeee;
        border-radius: 10px;
        padding: 10px 20px;
        margin-bottom: 20px;
        span {
            font-style: italic;
            font-family: "NunitoSans-SemiBold", sans-serif;
        }
    }
    .color-label {
        width: 170px;
        border-radius: 7px;
        padding: 5px 0px;
        text-align: center;
        @include laptop {
            width: 135px;
        }
        .label-text {
            font-size: $font14;
            line-height: $lheight18;
        }
    }
    .userpanel-header {
        .status-block {
            @include lg {
                display: flex;
            }
            @include md {
                display: block;
            }
        }
        .btn-medium {
            height: 40px;
            @include sm {
                height: 35px;
            }
        }
        .btn-pay-now {
            position: relative;
            padding-left: 30px;
            &::before {
                content: "";
                position: absolute;
                left: 15px;
                background: url("../../../images/svg-icon/pay-now-icon.svg") no-repeat;
                width: 25px;
                height: 25px;
                top: 6px;
                background-position: center !important;
            }
            &:hover,
            &:focus {
                &::before {
                    background: url("../../../images/svg-icon/pay-now-green-icon.svg") no-repeat;
                }
            }
        }
        .btn-edit {
            position: relative;
            width: 80px;
            padding-left: 25px;
            &::before {
                content: "";
                position: absolute;
                left: 5px;
                background: url("../../../images/svg-icon/edit-white-icon.svg") no-repeat;
                width: 25px;
                height: 25px;
                top: 6px;
                background-position: center !important;
            }
            &:hover,
            &:focus {
                &::before {
                    background: url("../../../images/svg-icon/edit-green-icon.svg") no-repeat;
                }
            }
        }
        .btn-pdf {
            position: relative;
            padding-left: 25px;
            @include sm {
                width: 165px;
                height: 40px;
            }
            &::before {
                content: "";
                position: absolute;
                left: 5px;
                background: url("../../../images/svg-icon/pdf-white-icon.svg") no-repeat;
                width: 20px;
                height: 20px;
                background-size: 100%;
            }
            &:hover,
            &:focus {
                &::before {
                    background: url("../../../images/svg-icon/pdf-green-icon.svg") no-repeat;
                    background-size: 100%;
                }
            }
        }
        .btn-assign-accountant {
            height: 40px;
            position: relative;
            width: 180px;
            text-align: right;
            @include sm {
                width: 165px;
            }
            &::before {
                content: "";
                position: absolute;
                left: 5px;
                background: url("../../../images/svg-icon/assign-role-white-icon.svg") no-repeat;
                width: 20px;
                height: 20px;
                background-size: 100%;
            }
            &:hover,
            &:focus {
                &::before {
                    background: url("../../../images/svg-icon/assign-role-green-icon.svg") no-repeat;
                    background-size: 100%;
                }
            }
        }
        .back-icon {
            cursor: pointer;
            background-color: $light-green-color;
            border: 1px solid $light-green-color;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            position: relative;
            transition: 300ms ease-in-out;
            &::after {
                content: "";
                position: absolute;
                border: solid $white-color;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                left: 15px;
                top: 14px;
                margin: auto;
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
            }
            &:hover {
                background: $white-color;
                border: 1px solid $light-green-color;
                &::after {
                    content: "";
                    position: absolute;
                    border: solid $light-green-color;
                    border-width: 0 2px 2px 0;
                    display: inline-block;
                    padding: 4px;
                    left: 15px;
                    top: 14px;
                    margin: auto;
                    transform: rotate(135deg);
                    -webkit-transform: rotate(135deg);
                }
            }
        }
        .applicant-info {
            height: 100%;
            margin-bottom: 15px;
            &.status-info {
                display: flex;
                @include lg {
                    width: 50%;
                }
                @include md {
                    width: 100%;
                }
                .left-panel {
                    width: 50%;
                }
                .right-panel {
                    width: 50%;
                }
            }

            .left-panel {
                @include mobile {
                    padding-right: 5px;
                }
            }
            // .left-panel , .right-panel{
            //     margin-bottom: 10px;
            // }
            .status-dropdown {
                width: 100%;
                .input-block.dropdown-block {
                    max-width: 170px;
                    width: 100%;
                    margin: 0px;
                    button {
                        padding: 5px 10px;
                    }
                }
                .dropdown-block .dropdown .dropdown-toggle:focus {
                    border: none !important;
                }
                .dropdown-block .dropdown .dropdown-toggle {
                    &:after {
                        top: 35%;
                        @include sm {
                            right: 10px;
                        }
                    }
                    &:focus {
                        border: none !important;
                    }
                    @include laptop {
                        padding: 11px;
                    }
                }
            }
        }
    }
    .userpanel-payment-section {
        .payment-plan-dropdown,
        .payment-plan-input {
            @include md {
                margin-top: 10px;
                margin-bottom: 10px;
            }
            .input-block {
                max-width: 235px;
                min-width: 235px;
                .form-input {
                    @include sm {
                        padding: 10px;
                    }
                }
            }
        }
        .payment-plan-block {
            @include lg {
                margin: 15px 0px;
            }
            .payment-plan-dropdown {
                .dropdown-block {
                }
            }
        }
    }
    .user-blank-section {
        display: block;
        @include md {
            display: none;
        }
    }
    .userpanel-view-section {
        margin-top: 30px;
        .application-status-panel {
            width: 170px;
            border-radius: 7px;
            padding: 5px 0px;
            height: 30px;
            @include laptop {
                width: 135px;
            }
            .application-status {
                font-size: $font14;
                line-height: $lheight18;
            }
            &.user-application-status {
                @include xl {
                    margin: 0px;
                }
            }
        }
        .userpanel-block {
            .document-upload-heading {
                .btn-small {
                    width: 160px;
                }
            }
            .userpanel-heading {
                position: relative;
                padding-left: 50px;
                @include sm {
                    margin-bottom: 10px;
                }
                .userpanel-heading-text {
                    font-size: $font18;
                    line-height: $lheight22;
                    color: $dark-back-color;
                    &.userpanel-subheading-text {
                        color: $light-gray-color;
                    }
                }
                &::before {
                    content: "";
                    position: absolute;
                    width: 35px;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 30px;
                }
                &.payment-history::before {
                    background: url("../../../images/svg-icon/payment-history.svg") no-repeat center;
                }
                &.payment::before {
                    background: url("../../../images/svg-icon/credit-card-icon.svg") no-repeat center;
                }
                &.about::before {
                    background: url("../../../images/svg-icon/about-preview-icon.svg") no-repeat center;
                }
                &.family::before {
                    background: url("../../../images/svg-icon/family-preview-icon.svg") no-repeat center;
                }
                &.residence::before {
                    background: url("../../../images/svg-icon/residence-preview-icon.svg") no-repeat center;
                }
                &.cra::before {
                    background: url("../../../images/svg-icon/cra-preview-icon.svg") no-repeat center;
                }
                &.deposite:before {
                    background: url("../../../images/svg-icon/deposit-icon.svg") no-repeat center;
                }
                &.upload::before {
                    background: url("../../../images/svg-icon/upload-preview-icon.svg") no-repeat center;
                }
                &.notes:before {
                    background: url("../../../images/svg-icon/user-notes.svg") no-repeat center;
                }
            }
            .userview-data-panel {
                position: relative;
                border: 1px solid $light-gray-color;
                border-bottom: 0px;
                border-right: 0px;
                margin-top: 15px;
                &.notes-data-panel {
                    border: 1px solid $light-gray-color;
                    padding: 20px;
                }
                @include md {
                    margin-top: 30px;
                    border-bottom: 0px;
                }
                &.dependants-detail-panel {
                    border: none;

                    @include md {
                        margin-top: 20px;
                    }
                    .dependent-detail-row {
                        margin-bottom: 10px;
                        border-right: 1px solid $light-gray-color;
                        .field-row {
                            border: 1px solid $light-gray-color;
                            border-right: 0px;
                            @include lg {
                                border-bottom: 0px;
                            }
                        }
                        @include lg {
                            .row {
                                .col-12 {
                                    &:last-child {
                                        border-bottom: 1px solid $light-gray-color;
                                    }
                                }
                            }
                        }
                        .number-block {
                            margin-right: 5px;
                            background: $light-gray-color;
                            background-position: center;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 35px;
                            .number {
                                font-size: $font16;
                                line-height: $lheight20;
                                color: $white-color;
                            }
                        }
                    }
                }

                &.userpanel-document-section {
                    border: 1px solid $light-gray-color;
                    .export-btn-panel {
                        padding: 20px 20px 0;
                        text-align: right;
                        .btn-small {
                            width: 145px;
                        }
                    }
                }
                &.payment-history-panel {
                    @include xl {
                        border-top: 0px;
                        border-left: 0px;
                    }
                    .payment-history-block {
                        @include xl {
                            margin-bottom: 20px;
                            border-top: 1px solid $light-gray-color;
                            border-left: 1px solid $light-gray-color;
                        }
                    }
                }
                .field-row {
                    border-bottom: 1px solid $light-gray-color;
                    border-right: 1px solid $light-gray-color;

                    .title-panel {
                        padding: 0px 20px;
                        background-color: $table-background-color;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        height: 70px;
                        @include xl {
                            padding: 0px 10px;
                        }
                        .title-text {
                            font-size: $font16;
                            line-height: $lheight22;
                            color: $light-gray-color;
                            @include xl {
                                font-size: $font14;
                                line-height: $lheight18;
                            }
                            @include mobile {
                                font-size: $font12;
                                line-height: $lheight16;
                            }
                        }
                    }
                    .data-panel {
                        padding: 0px 20px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        height: 70px;
                        @include lg {
                            padding: 15px 10px;
                            height: auto;
                        }
                        &.sin-panel {
                            .form-group {
                                @include mobile {
                                    margin-right: 0px !important;
                                }
                            }
                            .input-block {
                                .form-input {
                                    @include xl {
                                        padding: 10px 5px;
                                    }
                                    @include mobile {
                                        padding: 10px 1px;
                                    }
                                }
                            }
                        }
                        .data-text {
                            font-size: $font16;
                            line-height: $lheight22;
                            color: $black-color;
                            @include xl {
                                font-size: $font14;
                                line-height: $lheight18;
                            }
                            @include mobile {
                                font-size: $font12;
                                line-height: $lheight16;
                            }
                        }
                    }
                }
            }
        }
    }
    .upload-accordion {
        margin: 20px 0px;
    }
    .btn-upload {
        width: 200px;
        height: 40px;
    }
    .btn-small {
        width: 160px;
    }
}
.save-image-icon {
    cursor: pointer;
    @include md {
        margin-left: 5px !important;
    }
}
.edit-image-icon {
    cursor: pointer;
}

button.color-label.green-label.dropdown-toggle.btn.btn-primary {
    border: none !important;
}
button.color-label.red-label.dropdown-toggle.btn.btn-primary {
    border: none !important;
}
button.color-label.partial-paid-label.dropdown-toggle.btn.btn-primary {
    border: none !important;
}
.status-dropdown {
    .dropdown-block .dropdown .dropdown-toggle:focus {
        border: none !important;
    }
}
