.contactus-banner-section,
.banner-mobile-section.contactus-mobile-section {
    background: url("../../images/contact-us/contactus-banner.png") no-repeat;
    background-size: 75%;
    background-position: top right !important;
    @include xl {
        background-size: cover;
    }
    @include md {
        background-position: bottom left !important;
    }
    .banner-description {
        padding-left: 100px;
        @include sm {
            padding-left: 32px;
        }
    }
}
.contact-section {
    padding: 150px 0 50px;
    @include lg {
        padding: 50px 0;
    }
    .section-heading-panel {
        .heading-text {
            font-size: $font50;
            @include xl{
                font-size: $font40;
            }
        }
    }
    .get-in-touch-panel {
        padding-right: 150px;
        @include laptop{
            padding-right: 20px;
        }
        .contact-details-panel {
            margin-top: 50px;
            .contact-item {
                position: relative;
                padding-left: 100px;
                height: 60px;
                display: flex;
                align-items: center;
                margin-bottom: 30px;
                @include sm{
                    padding-left: 70px;
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 60px;
                    height: 60px;
                }
                &.address-item {
                    display: block;
                    padding-top: 8px;
                    &:before {
                        background: url("../../images/svg-icon/contact-address-icon.svg") no-repeat;
                    }
                }
                &.email-item {
                    &:before {
                        background: url("../../images/svg-icon/contact-email-icon.svg") no-repeat;
                    }
                }
                &.phone-item {                   
                    &:before {
                        background: url("../../images/svg-icon/contact-phone-icon.svg") no-repeat;
                    }
                }
                &.fax-item {                    
                    &:before {
                        background: url("../../images/svg-icon/fax-contactus-icon.svg") no-repeat;
                        background-position: center;
                    }
                }
                &.office-hours-item {
                    &:before {
                        background: url("../../images/svg-icon/contact-office-hours-icon.svg") no-repeat;
                    }
                }
                span {
                    line-height: $lheight24;
                }
            }
        }
    }
    .contact-form-panel {
        border-radius: 15px;
        box-shadow: 0 16px 40px rgba(154, 170, 207, 0.2);
        padding: 45px 30px;
        position: relative;
        @include sm{
            padding: 35px 20px;
        }
        .contact-form {     
            .phonenumber-panel{
                .number-prefix{
                    top: 20px;
                }
            }       
            .form-group {
                margin-bottom: 25px;
                .form-input {
                    background: $lightblue-back-color;
                    border-color: $lightblue-back-color;
                    border-radius: 0;
                    height: 60px;
                    &.textarea-input {
                        height: auto;
                        padding: 20px;
                    }
                }
                .btn-small {
                    position: absolute;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    bottom: -20px;
                    font-size: $font16;
                }
            }
        }
    }
    .map-panel{
        margin-top: 100px;
    }
}
